import React from 'react';


import List from "@material-ui/core/List";

import PlaceSelect from "../../components/PlaceSelect";
import PlaceListTabs from "../../components/PlaceListTabs";
import PlaceAmount from "../../components/PlaceAmount";
import NewPlaceAlert from "../../components/NewPlaceAlert";
import PlaceListItem from "./PlaceListItem";
import { useTranslation } from 'react-i18next';

import "../../styles/place-list.scss";
import PublicListEmptyAlert from "../../components/PublicListEmptyAlert";

const PlacesList = ({
  places,
  placeAmount,
  taisakuAmount,
  disabledTaisaku,
  handlePlaceListClick,
  handlePlaceListHover,
  filterByTaisaku,
  canQueryMore,
  placeListTabSwitch,
  loading,
  loadingTabSwitch,
  handleCheckbox,
  checkboxData,
  amountSelected,
  selectedOpen,
  handleSelectedOpen,
  incidentTagFormOpen,
  handleSelectedClose,
  handleCheckAll,
  handleMakeTaisaku,
  handleMarkSelectedAsDeleted,
  handlePrintSelected,
  handleExportSelected,
  selectedAction,
  loadingAction,
  alertNewPlace,
  alertCounter,
  hideAlert,
  getPlacesByArea,
  getRecentPlaces,
  handlePrintViewMode,
  handleCreateIncidentTag,
  incidentTags,
  hasTaisaku,
  filterByLists,
  allChecked,
  loadMoreButton,
  getImages,
  actionProgress,
  publicListEmptyAlert,
  publicTabVisible
}) => {


  const {t} = useTranslation();
  return (
    <div>
      <div>
        {publicTabVisible?
          <PlaceListTabs
            placeListTabSwitch={placeListTabSwitch}
            key="tab-keys-1"
          />: null
        }
        <PlaceSelect
          key="amount-select-key-1"
          selectedOpen={selectedOpen}
          incidentTagFormOpen={incidentTagFormOpen}
          handleSelectedOpen={handleSelectedOpen}
          handleSelectedClose={handleSelectedClose}
          handleCheckAll={handleCheckAll}
          handleMakeTaisaku={handleMakeTaisaku}
          handleMarkSelectedAsDeleted={handleMarkSelectedAsDeleted}
          handlePrintSelected={handlePrintSelected}
          handleExportSelected={handleExportSelected}
          selectedAction={selectedAction}
          actionProgress={actionProgress}
          filterByLists={filterByLists}
          loadingAction={loadingAction}
          getPlacesByArea={getPlacesByArea}
          handlePrintViewMode={handlePrintViewMode}
          handleCreateIncidentTag={handleCreateIncidentTag}
          incidentTags={incidentTags}
          hasTaisaku={hasTaisaku}
          disabledTaisaku={disabledTaisaku}
          allChecked={allChecked}
        />
        <PlaceAmount
          placeAmount={placeAmount}
          filterByTaisaku={filterByTaisaku}
          disabledTaisaku={disabledTaisaku}
          taisakuAmount={taisakuAmount}
          amountSelected={amountSelected}
        />
        <NewPlaceAlert
          getRecentPlaces={getRecentPlaces}
          hideAlert={hideAlert}
          alertCounter={alertCounter}
          alertNewPlace={
            filterByLists[t('Incident')].length > 0 ? false : alertNewPlace
          }
        />
        <PublicListEmptyAlert publicListEmptyAlert={publicListEmptyAlert} />
      </div>
      <div className={`places-wrapper ${alertNewPlace ? "has-new" : "no-new"} ${publicTabVisible ? "" : "public-tab-hidden"}`}>
        <List className="place-list-container has-public-tabs">
          {loadingTabSwitch ? null : (
            <div>
              {places &&
                places.map((place) => {
                  const lowResImage = place.images && place.images.find((im) => im.includes("low"));
                  const firstImage = place.images && place.images[0];
                  const thumbnailURL = lowResImage || firstImage || null;

                  const shouldDisplay = (
                    (place.isViewable &&
                      !filterByTaisaku &&
                      place.taisaku !== "true" &&
                      !place.isLiveStream) ||
                    (place.isViewable &&
                      filterByTaisaku &&
                      place.taisaku === "true" &&
                      !place.isLiveStream) ||
                    (place.isViewable &&
                      filterByLists[t('Incident')].length > 0)
                  );

                  return shouldDisplay ? (
                    <PlaceListItem
                      key={`placeListItem-${place.placeId}`}
                      place={place}
                      thumbnailURL={thumbnailURL}
                      handlePlaceListClick={handlePlaceListClick}
                      handlePlaceListHover={handlePlaceListHover}
                      handleCheckbox={handleCheckbox}
                      checkboxData={checkboxData}
                      getImages={getImages}
                    />
                  ) : null;
                })}
            </div>
          )}
          {canQueryMore || loadingTabSwitch ? loadMoreButton() : null}
        </List>
      </div>
    </div>
  );
};

export default PlacesList;
