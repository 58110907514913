import React from "react";
import firebase from "firebase/app";
import 'firebase/database';

import Filters from "../../containers/Filters";
import HazardMap from "../../containers/HazardMap";
import PlacesList from "../../containers/PlacesList";
import LiveStreamList from "../../containers/LiveStreamList";
import Linkify from 'react-linkify';
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
  Checkbox,
  Dialog
} from "@material-ui/core";

import PrintView from "../../components/PrintView";

import MonitorModeScreen from "../../components/MonitorModeScreen";
import ModalPlaceDetails from "../../components/ModalPlaceDetails";

//new viewer
import LiveStreamSingle from "../../components/LiveStreamSingle";

//old one still using for storage
import ModalLiveStreamDetails from "../../components/ModalLiveStreamDetails";


import ModalLastLocationDetails from "../../components/ModalLastLocationDetails";
import ModalCommentDetails from "../../components/ModalCommentDetails";
import ModalFacilityDetails from "../../components/ModalFacilityDetails";
import NavigationBar from "../../components/NavigationBar";
import UserInfoBar from "../../components/UserInfoBar";
import EditPlaceListDialog from "../../components/EditPlaceListDialog";
import axios from 'axios';
import moment from "moment";
import "moment/locale/ja";

import CircularProgress from "@material-ui/core/CircularProgress";

import SendIcon from '@material-ui/icons/Send';

import LastLocationIcon from '@material-ui/icons/MyLocation';
import HazardIcon from "../../images/stack-pin.svg"
import "../../styles/base.scss";
import "../../styles/map.scss";
import "../../styles/filter-nav.scss";

import AlertSnackbar from "../../components/AlertSnackbar";
import DefaultSettingsDialog from "../../components/DefaultSettingsDialog";
import HazardUserSettings from "../../components/HazardUserSettings";
import HazardCamDialog from "../../components/HazardCamDialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeleteNotificationDialog from "../../components/DeleteNotificationDialog";
import MarkerClusterer from '@googlemaps/markerclustererplus';

import FilterDialog from '../../components/FilterDialog';

import TogglePublicTabDialog from "../../components/TogglePublicTabDialog";
import { downloadKml } from "../../helpers/export-to-kml";
import HazardLiveRealtimeMapBuilder from "../../components/HazardLiveRealtimeMapBuilder";

import ToggleNotificationSoundDialog from "../../components/ToggleNotificationSoundDialog";
import { withTranslation } from 'react-i18next';

import { io } from "socket.io-client";
import i18n from '../../i18n';

//PLACE HELPERS
import {
  checkGroupId,
  checkIncidentTags,
  checkTagLevelName,
  checkTagLevelId,
  filterDeleted,
  hasLevelNumber,
  sortByCreateTime,
  getPlaces,
  queryPlaces,
  queryPlacesDateRange,
  handleCreateIncidentTag,
  getPlacesByArea,
  formatListings,
  updatePlace
} from "../../helpers/placesHelper";

//NOTIFICATION HELPERS
import {
  sendNotifications,
  sendNotificationsUser,
  handleCheckAllUserNotify,
  handleCheckGroupNotify,
  handleCheckUserNotify,
  handleDeleteNotification,
  checkNotificationHistory
} from "../../helpers/notifications";

//GRAPHIC HELPERS
import {
  CustomCheckbox,
  LocationIcon,
  RealtimeIcon,
  CommentIcon
} from "../../helpers/icons";

const geocore = require("@mekasmith/geocore");
var _ = require("lodash");

geocore.objects.bins.listWithQuality = function(id, quality) {
  return geocore.get(`/objs/${id}/bins?quality=${quality}`);
};


// so we can find semmetric difference
//Array.prototype.diff = function(arr2) { return this.filter(x => !arr2.includes(x)); }

function arrayDiff(arr1, arr2) {
  return arr1.filter(x => !arr2.includes(x));
}


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}
moment.locale(i18n.language === "en"? "en": "ja");

i18n.on('languageChanged', function(lng) {
  moment.locale(lng === "en"? "en": "ja");
});

const isStreamTestMode = process.env.REACT_APP_STREAM_SERVER === "DEV"? true: false;
const isLiveAppMode = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

class Dashboard extends React.Component {
  state = {
    map: null,
    markers: null,
    open: false,
    openLiveStream: false,
    editPlaceOpen: false,
    selectedPlace: null,
    places: null,
    placeAmount: 0,
    taisakuAmount: 0,
    amountSelected: 0,
    alertCounter: 0,
    selectedAction: null,
    selectedOpen: false,
    openLastLocation: null,
    selectedLastLocation: null,
    lastLocationMarkers: null,
    users: null,
    openFacility: null,
    selectedFacility: null,
    facilityMarkers: null,
    facilities: [],
    comments: null,
    openComment: null,
    selectedComment: null,
    commentMarkers: null,
    pageNumber: 1,
    numberPerPage: 25,
    loadingPlaces: true,
    loadingMoreList: false,
    canQueryMore: false,
    groups: null,
    startDate: null,
    endDate: null,
    filterByLists: {
      緊急度: [],
      端末表示: [],
      表示期間: [],
      端末最終地点: [],
      施設情報: [],
      管理者コメント: [],
      ユーザ: [],
      インシデント: [],
      "Urgency Level": [],
      "Groups": [],
      "Date Range": [],
      "Device Last Location": [],
      "Facility Information": [],
      "Admin Comment": [],
      "User": [],
      "Incident": []
    },
    filterByTaisaku: false,
    pageCount: 1,
    videoListShow: false,
    photoListShow: true,
    screenBuilderOpen: false,
    realtimeMapBuilderOpen: false,
    isDragging: false,
    dragEnd: null,
    draggedPlace: null,
    openSidebar: true,
    openRealtimeMapSidebar: false,
    activeButton: 1,
    loadingPlacesByArea: false,
    hasError: false,
    errorMessage: null,
    hazardLiveSocket: null,
    webRTCLiveList: [],
    hazardCamLiveStreamList: [],
    isPublicOpen: false,
    currentTab: "all",
    loadingPublicPlace: false,
    checkboxData: {},
    handleCheckAll: false,
    monitorRoomsConnected: [],
    zoomLevel: 6,
    openSettings: false,
    mostRecentPlaceTimestamp: null,
    alertNewPlace: false,
    streamsActiveCount: null,
    hazardCamLiveStreamListCount: null,
    openUserSettings: false,
    hazardCamOpen: false,
    hazardCamType: null,
    loadingHazardName: false,
    loadingDelete: false,
    filterRange: {
      range: i18n.t("< Week"),
      startDate: moment().utc().subtract(1, "week").startOf("day").format("YYYY/MM/DD hh:mm:ss"),
      endDate: moment().utc().endOf("day").format("YYYY/MM/DD HH:mm:ss")
    },
    printViewMode: "single",
    incidentTagFormOpen: false,
    disabledTaisaku: false,
    allChecked: false,
    requestingUpdateLastLocation: false,
    prevSelectedLastLocationMarkerIds: null,
    dialogNotifyOpen: false,
    checkedAlertGroupIds: [],
    checkedAlertUserIds: [],
    sendingNotification: false,
    allocatedPlaceAmount: 50,
    pagePlaceAmount: 200,
    valueNotificationTab: 0,
    notificationHistory: null,
    snapshotOpen: false,
    editPlaceDeleteOpen: false,
    selectedNotification: null,
    showRealtimeMarkers: false,
    imgLoading: false,
    refreshMapOnMapMove: false,
    loadingAction: false,
    printingPlaceList: [],
    actionProgress: {
      complete: 0,
      total: 0
    },
    openFilterDialog: false,
    selectedTag: null,
    publicListEmptyAlert: false,
    publicTabVisible: false,
    notificationSound: false,
    openTogglePublic: false,
    openNotificationSound: false,
    publicTabToggleLoading: false,
    openHazardCamStream: false,
    hazardCamRoomId: null,
    hazardCamRoomInfo: null,
    player: null,
    loadingStream: false,
    hoveredLivePlace: null,
    activeRealtimeLocationStreamCount: 0,
    unmountLoading: false,
    openViduStreamPlaces: {},
    openLiveStorage: false,
    selectedLivePlace: null,
  };

  notifyTitle = React.createRef();
  notifyMessage = React.createRef();

  notifyTitleUser = React.createRef();
  notifyMessageUser = React.createRef();

  canvasStreamRef = React.createRef();

  componentDidMount() {
    if (this.props.isLiveApp) {
      this.setState({ 
        activeButton: 2,
        videoListShow: true,
        photoListShow: false
      });
    } else {
      this.runHeartbeat();
      this.runHeartbeatUrl();
    }
    this.checkOS();
    if (this.props.isLiveApp) {
      this.connectSocket();
      this.storageLimitAdjustment();
    }
    this.connectionManager();
    this.firstTimeGetPlaces();

    this.notifyRef = checkNotificationHistory({
      parentGroupId: this.props.parentGroup.id,
      setState: this.setState.bind(this),
      firebase,
    });

    this.checkVisiblePublicTab();
    this.checkNotificationSoundStatus();

  }

  componentWillUnmount() {
    clearTimeout(this.heartbeat);
    clearTimeout(this.heartbeatUrl);
    this.notifyRef?.off();
  };

  storageLimitAdjustment = () => {
    if(this.props.storageLimit < 1) {
      this.setState({filterRange: {
        range: "",
        startDate: moment().local().startOf("day").format("YYYY/MM/DD hh:mm:ss"),
        endDate: moment().local().endOf("day").format("YYYY/MM/DD hh:mm:ss")
      }});
    }
  }

  connectionManager = () => {
    /* TURNING THIS ON WILL LOGOUT DOUBLE USER LOGINS

    var connectionRef = firebase.database().ref('connections/' + this.props.user.id + "/logToken");
    connectionRef.on("value", (snap) => {
      //was logged in with another account, logout user
      if(this.props.logToken !== snap.val() && snap.val() !== null) {
        this.props.handleUserLogout();
      }
    });

    */
  }

  connectSocket = () => {
    // we connect to the socket on load because we need to get a list of active streams in realtime
    // this will prevent us having to refressh to page to see new active streams

    //for testing we will change type 'viewer' to 'admin_viewer'
    const options = {
      query: {
        id: this.props.user.id,
        geocore_token: this.props.token,
        type: 'viewer',
        name: this.props.user.name,
        company: this.props.parentGroup.id
      }
    }

    let customer = this.props.parentGroup.id;

    if(this.hazardLiveSocket) {
      this.hazardLiveSocket.disconnect();
    }


    if(isStreamTestMode) {
      this.hazardLiveSocket = io(`http://localhost:9000/${customer}`, options);
    } else {
      // prod server connection
      this.hazardLiveSocket = io(`https://livego-api.mmdev.jp/${customer}`, options);
    }

    this.hazardLiveSocket.on('nsLiveStreamList', (activeStreams) => {      
      //clear places by symmetric difference
      //let difference = this.state.webRTCLiveList.diff(activeStreams);

      let difference = arrayDiff(this.state.webRTCLiveList, activeStreams);

      //this will show us a filter list of our current active streams list and compare it to the new stream list, showing us the difference
      if(difference && difference.length > 0) {
        difference.forEach((d) => {
          this.setState(prevState => (
              {
                openViduStreamPlaces: {
                  ...prevState.openViduStreamPlaces,
                  [d]: null,
                }
              }
          ));
        });
      };

      if(activeStreams && activeStreams.length > 0) {  
        let countGetPlaces = 0;
        // need to fetch each place that is a active stream, for now lets do it one by one
        activeStreams.forEach((place) => {
          geocore.places.get(place).then((p) => {
            ++countGetPlaces;
            this.setState(prevState => (
              {
                openViduStreamPlaces: {
                  ...prevState.openViduStreamPlaces,
                  [place]: p,
                }
              }
            ));
            if(countGetPlaces === activeStreams.length) {
              this.setState({
                webRTCLiveList: activeStreams,
                streamsActiveCount: activeStreams.length,
              });
            }
          });
        });
      } else {
        this.setState({
          webRTCLiveList: activeStreams,
          streamsActiveCount: null,
          activeRealtimeLocationStreamCount: 0
        });
      }

      /* TODO-THIS IS FOR ACTIVATING THE REALTIME LOCATION WHICH WILL NEED TO BE REBUILT SLIGHTLY

      if(activeStreams && activeStreams.length > 0) {        
        this.isRealtimeLocationStream(activeStreams);
        this.setState({
          webRTCLiveList: activeStreams,
          streamsActiveCount: activeStreams.length,
        });
      } else {
        this.setState({
          webRTCLiveList: activeStreams,
          streamsActiveCount: null,
          activeRealtimeLocationStreamCount: 0
        });
      }

      */

    });

    if(this.hazardCamSocket) {


    this.hazardCamSocket.on('nsHazardCamLiveStreamList', (activeStreams) => {
      if(activeStreams && activeStreams.length > 0) {
      // for demoing we are just going to manually filter out ones we are not in
        var mycompany = this.props.parentGroup;
        var filterdDemoList = activeStreams.filter((s) => s.roomInfo.roomInfo.cityGroupName === mycompany.name);
        this.setState({
          hazardCamLiveStreamList: filterdDemoList,
          hazardCamLiveStreamListCount: filterdDemoList.length,
        });

      } else {
        this.setState({
          hazardCamLiveStreamList: activeStreams,
          hazardCamLiveStreamListCount: null
        });
      }
    });

    }


    this.hazardLiveSocket.on('roomConnect', (roomInfo) => {
      this.setState({
        roomInfo: roomInfo,
      });
    });

    this.hazardLiveSocket.on('roomLeave', (roomLeaving) => {
      this.setState({
        roomInfo: null,
      });
    });

    this.hazardLiveSocket.on('connectionUnstable', (unstable) => {
      const { t } = this.props;

      this.setState({ 
        hasError: true,
        errorType: "warning",
        errorMessage: t("Your internet connection is unstable."),
      });
    });

  };

  joinRoom = (roomId) => {
    if(this.hazardLiveSocket) {
      this.hazardLiveSocket.emit('joinRoomViewer', roomId);
    }
  };

  leaveRoom = (roomId) => {
    if(this.hazardLiveSocket) {
      this.hazardLiveSocket.emit('leaveRoomViewer', roomId);
    }
  }

  checkOS = () => {
    //frontend hack
    if (navigator.appVersion.indexOf("Win")!== -1) {
      this.setState({
        os: 'windows',
      });
    } else {
      this.setState({
        os: 'normal'
      })
    }
  }

  firstTimeGetPlaces = () => {
    if(this.getGeocorePlacesTimer) {
      clearTimeout(this.getGeocorePlacesTimer);
    }
    this.getGeocorePlacesTimer = setTimeout(() => {
      clearTimeout(this.getGeocorePlacesTimer);
      if(this.state.map && window.google.maps && this.props.groups) {
        this.getPlacesWrapper();
      } else {
        this.firstTimeGetPlaces();
      }

    }, 100);

  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.openFilterDialog && this.state.selectedTag) {
      if (
        prevProps.groups !== this.props.groups || 
        prevProps.tags !== this.props.tags || 
        prevProps.incidentTags !== this.props.incidentTags || 
        prevState.comments !== this.state.comments
      ) {
        this.setState({
          selectedFilterListObject: this.getFilterListObjs(this.state.selectedTag)
        });
      }
    }
    if (prevProps.facilityGroups !== this.props.facilityGroups) {
      this.createFacilityInfoMarkers();
    }
    if (prevProps.comments !== this.props.comments) {
      this.createCommentMarkers();
    }
    if (prevProps.groups !== this.props.groups) {
      this.createLastLocationMarkers(this.state.map);
    }
  };

  handleActivePlaceList = id => {
    const { markers, filterByTaisaku, places } = this.state;
    if(id === 1) {
      // photos
      this.setState({
        videoListShow: false,
        photoListShow: true,
      }, () => {
        // Only Show Place List Markers
        if (markers) {
          markers.forEach(marker => {
            const markerPlace = places.find(place => place.placeId === marker.placeId);

            const isLive = markerPlace.placeId.includes("-LIVE-");
            const isQueried = markerPlace.index < this.state.allocatedPlaceAmount;
            const isTaisaku = markerPlace.taisaku === "true";

            if (isLive) {
              marker.setVisible(false);  
              window.mc.removeMarker(marker);          
            } else if (isQueried) {
              if (filterByTaisaku) {
                if (isTaisaku) {
                  window.mc.addMarker(marker);
                  marker.setVisible(true);
                } else {
                  marker.setVisible(false);
                  window.mc.removeMarker(marker);
                }
              } else {
                if (isTaisaku) { 
                  marker.setVisible(false); 
                  window.mc.removeMarker(marker);
                } else {
                  window.mc.addMarker(marker);
                  marker.setVisible(true); 
                }
              } 
            } else {
              marker.setVisible(false);
              window.mc.removeMarker(marker);
            }
          });
        }
      });
    } else {
      // video
      this.setState({
        videoListShow: true,
        photoListShow: false,
      }, () => {
        // Only Show Live Markers
        if (markers) {
          markers.forEach(marker => {
            const markerPlace = places.find(place => place.placeId === marker.placeId);

            const isLive = markerPlace.placeId.includes("-LIVE-");
            const isStreaming = markerPlace.streaming;
            const isQueried = markerPlace.index < this.state.allocatedPlaceAmount;

            if (!isLive) {
              marker.setVisible(false);  
              window.mc.removeMarker(marker);          
            } else if (isQueried && !isStreaming) {
              window.mc.addMarker(marker);
              marker.setVisible(true);
            } else {
              marker.setVisible(false);
              window.mc.removeMarker(marker);
            }
          });
        }
      });
    }
  };

  clearAllMarkers = () => {
    let allMapMarkers = [];

    if(this.state.markers) {
      const markers = allMapMarkers
        .concat(
          this.state.markers, 
          this.state.lastLocationMarkers, 
          this.state.facilityMarkers, 
          this.state.commentMarkers
        );

      markers.forEach(marker => {
        marker.setVisible(false);
        window.mc.removeMarker(marker);
        marker.setMap(null);
      });
  
      this.setState({ 
        markers: null,
        lastLocationMarkers: null,
        facilityMarkers: null,
        commentMarkers: null,
      });
    } else {
      this.setState({ 
        loadingPlacesByArea: false,
      });
    }
  };

  clearPlaceListMarkers = (resetFilters) => {
    const { markers, places } = this.state;
    
    if (markers) {
      if(resetFilters) {
        //todo reset all here and then filter if not so we keep the other markers unrelated to the place list
      }
      markers.forEach(marker => {
        marker.setVisible(false);
        window.mc.removeMarker(marker);
        marker.setMap(null);
      });

      this.setState({ markers: null });
    }

  };

  loadMorePlaces = async () => {
    this.setState({ loadingMoreList: true });
    const { t } = this.props;
  
    const { allocatedPlaceAmount, places, pagePlaceAmount } = this.state;
    const additionalAmount = 10;

    console.log("allocatedPlaceAmount", allocatedPlaceAmount);
    console.log("places.length", places.length);
  
    if (allocatedPlaceAmount >= pagePlaceAmount) {
      const nextPage = true;
      await this.setState(prevState => ({
        pageNumber: prevState.pageNumber + 1,
        pagePlaceAmount: prevState.pagePlaceAmount + 200
      }));
      await this.getPlacesWrapper(nextPage);
  
      // After fetching new places, increase allocatedPlaceAmount
      const newAllocatedPlaceAmount = this.state.allocatedPlaceAmount + additionalAmount;
      const canQueryMore = this.state.places.length > newAllocatedPlaceAmount;
  
      await this.setState({
        allocatedPlaceAmount: newAllocatedPlaceAmount,
        canQueryMore,
        loadingMoreList: false,
      });
  
      this.filterItems();
  
      if (!canQueryMore) {
        this.setState({
          hasError: true,
          errorType: "warning",
          errorMessage: t("No more reports found")
        });
      }
    } else if (places.length > allocatedPlaceAmount) {
      const canQueryMore = places.length > allocatedPlaceAmount + additionalAmount;
      await this.setState(prevState => ({
        canQueryMore,
        allocatedPlaceAmount: prevState.allocatedPlaceAmount + additionalAmount,
        loadingMoreList: false,
      }));
      this.filterItems();
      if (!canQueryMore) {
        this.setState({
          hasError: true,
          errorType: "warning",
          errorMessage: t("No more reports found")
        });
      }
    } else {
      this.setState({
        loadingMoreList: false,
        canQueryMore: false,
        hasError: true,
        errorType: "warning",
        errorMessage: t("No more reports found")
      });
    }
  };

  loadMorePublicPlaces = () => {
    this.setState({ loadingMoreList: true });
    const { t } = this.props;

    axios({
      method: 'GET',
      url: `https://hazardalert.geocore.jp:4000/places`,
      params: {
        numberPerPage: 25,
        pageNumber: 1
      }
    })
    .then(response => {
      // Format and Filter Items with Places pulled from the PublicDB
      const publicPlaces = response.data.places;
      if (publicPlaces && publicPlaces.length > 0) {
        this.formatListings(publicPlaces);
      } else {
        this.setState({
          hasError: true,
          loadingMoreList: false,
          errorType: "warning",
          errorMessage: t("No more reports found")
        });
      }
    })
    .catch(error => {
      // An error occured with Axios call to PublicDB
      console.log(error);
      this.setState({ loadingTabSwitch: false });
    });
  };

  getPlacesWrapper = async (nextPage) => {
    const { t, groups, user } = this.props;
    const { map, filterRange, pageNumber } = this.state;
    const customData = user?.customData || null;

    // Call the helper's getPlaces function
    await getPlaces({
      nextPage,
      t,
      groups,
      queryPlaces: async (groupIds, nextPage) =>
        queryPlaces({
          groupIds,
          nextPage,
          map,
          filterRange,
          pageNumber,
          customData,
        }),
      formatListings: this.formatListings,
      filterItems: this.filterItems,
      setState: this.setState.bind(this),
    });
  };

  //TODO 

    getNotificationNewPlaces = async () => {
      const { map, places, allocatedPlaceAmount } = this.state;
  
      this.setState({ loadingPlaces: true, alertNewPlace: false, alertCounter: 0 });
  
      var groupIds = [];
      this.props.groups.forEach(group => {
          groupIds.push("TAG-TNHP-1-PT-" + group.id);
      });
  
      const newPlaces = await this.queryRecentPlaces(groupIds);
  
      // Filter out places we already have
      const filteredNewPlaces = newPlaces.filter(place => {
          return !places.some(p => p.placeId === place.id);
      });
  
  
      const limitedPlaces = filteredNewPlaces.slice(0, allocatedPlaceAmount);
  
      if (limitedPlaces.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();
  
          limitedPlaces.forEach(place => {
              bounds.extend({ lat: place.point.latitude, lng: place.point.longitude });
          });
  
          map.fitBounds(bounds);
  
          const { user } = this.props;
  
          const initialZoom = user.customData && user.customData.initialZoom
              ? parseInt(user.customData.initialZoom)
              : 8;
          map.setZoom(initialZoom);
  
          this.formatRecentListings(limitedPlaces);
      } else {
          this.setState({ loadingPlaces: false });
      }
  };
  
  queryRecentPlaces = async groupIds => {
    const query = new geocore.places.query();
    const { mostRecentPlaceTimestamp } = this.state;
    let places = [];
    let filteredPlaces = [];

    const formattedDateString = moment(mostRecentPlaceTimestamp).subtract(9, 'h').format("YYYY/MM/DD hh:mm:ss");
    console.log("formattedDateString", formattedDateString);
    places = await query
      .withTagIds(groupIds)
      .withTagDetails()
      .createdAfter(formattedDateString)
      .orderByRecentlyCreated()
      .all();

    if (places.length > 0) {
      let filteredLivePlaces = places.filter(place => !place.id.includes("-LIVE-"));
      filteredPlaces = filterDeleted(filteredLivePlaces);
    }

    return filteredPlaces;
  };

  formatRecentListings = newPlaces => {
    var newPlacesList = [];
    var newPlaceIndex = 0;
    const { t } = this.props;

    var { customData } = this.props.parentGroup;
    newPlaces.forEach((place, index, array) => {
      var comment = place.customData ? place.customData["comment.1"] : "";
      var updatedBy = place.customData ? place.customData["updatedBy"] : "";
      var createTime = moment.utc(place.createTime).local().format("YYYY/MM/DD (ddd) HH:mm");
      var updateTime = moment.utc(place.updateTime).local().format("YYYY/MM/DD (ddd) HH:mm");
      var geo = place.point;
      var title = place.shortDescription;
      var groupName = place.customData ? place.customData["group.name"] : "";
      var placeId = place.id;
      let groupId = checkGroupId(place.tags);
      var tagId = checkTagLevelId(place.tags);
      var tagLevelName = checkTagLevelName(place.tags);
      var incidentTags = checkIncidentTags(place.tags);
      var customName = customData ? customData[tagLevelName] : "";
      var user = place.customData ? place.customData["user.name"] : "";
      var taisaku = place.customData ? place.customData["taisaku"] : "";
      var hasRead = place.customData ? place.customData["hasRead"] : "";
      var isDeleted = place.customData ? place.customData["deleted"] : "";
      var isLiveStream = place.id.includes("-LIVE-");
      var duration = place.customData ? place.customData["video_duration"] : "";
      var isPublic = false;
      var isStreaming = false;
      var streamingStorage = false;
      var isSos = false;

      if (place.customData && place.customData["is_sos"]) {
        if (place.customData["is_sos"] === "true") {
         isSos = true;
        } else {
          isSos = false;
        }
      }


      if (place.customData && place.customData["isPublic"]) {
        if (place.customData["isPublic"] === "true") {
         isPublic = true;
        } else {
          isPublic = false;
        }
      }

      this.getImages(place).then(URLs => {
        newPlacesList.push({
          index,
          placeId: placeId,
          hazardLevel: { id: tagId, name: tagLevelName, customName: customName },
          incidentTags: incidentTags,
          createTime: createTime,
          updateTime: updateTime,
          title: title,
          groupName: groupName,
          groupId: groupId,
          user: user,
          comment: comment,
          updatedBy: updatedBy,
          images: URLs.photoURLs,
          video: URLs.videoURL,
          geo: geo,
          isViewable: true,
          taisaku: taisaku,
          hasRead: hasRead,
          isDeleted: isDeleted,
          duration: duration,
          isLiveStream: isLiveStream,
          streaming: isStreaming,
          streamHasStorage: streamingStorage,
          isPublic: isPublic,
          isSos: isSos,
        });

        newPlaceIndex++;

        if (newPlaceIndex === array.length) {
          if (this.state.currentTab === "all") {
            const newPlaces = this.state.places? [
              ...sortByCreateTime(newPlacesList),
              ...sortByCreateTime(this.state.places)]:
              sortByCreateTime(newPlacesList);
            this.setState(prevState => ({
              places: newPlaces,
              currentTab: "all",
              filterByLists: {
                ...prevState.filterByLists,
                [t("Urgency Level")]: [],
                [t("Devices")]: [],
                [t("Date Range")]: [],
                [t("Person")]: [],
                [t("Incident")]: []
              },
              filterByTaisaku: false
            }),
            () => {
              this.viewablePlacesAmount();
              this.setCheckboxData();
              this.createRecentMarkers(newPlacesList, this.state.map);
              this.filterItems();
            });
          } else {
            this.getPlacesByArea(true, newPlacesList);
          }
        }
      });
    });
  };
 
  // this is the yellow top refresh button on the dashboard (not the alert one)
  getPlacesByArea = async (resetFilters, newPlacesList, ignoreViewMore) => {
    const { t, groups } = this.props;
    const { map, filterRange } = this.state;

    await getPlacesByArea({
      resetFilters,
      newPlacesList,
      map,
      filterRange,
      groups,
      t,
      clearPlaceListMarkers: this.clearPlaceListMarkers,
      formatListings: this.formatListings,
      setState: this.setState.bind(this),
      filterDeleted,
      sortByCreateTime,
      ignoreViewMore
    });
  };


  formatListings = async (places, nextPage, newPlacesList) => {
    const {
      parentGroup,
      isLiveApp,
      t,
    } = this.props;

    const {
      allocatedPlaceAmount,
      places: currentPlaces,
    } = this.state;

    await formatListings({
      places,
      nextPage,
      newPlacesList,
      parentGroup,
      isLiveApp,
      allocatedPlaceAmount,
      currentPlaces,
      setState: this.setState.bind(this),
      getLiveStreamImagesAndVideo: this.getLiveStreamImagesAndVideo.bind(this),
      getImages: this.getImages.bind(this),
      sortByCreateTime,
      viewablePlacesAmount: this.viewablePlacesAmount.bind(this),
      setCheckboxData: this.setCheckboxData.bind(this),
      createMarkers: this.createMarkers.bind(this),
      filterItems: this.filterItems.bind(this),
      runHeartbeat: this.runHeartbeat.bind(this),
    });
  };

  viewablePlacesAmount = () => {
    const { filterByTaisaku, places, filterByLists } = this.state;
    const {t} = this.props;
    const incidents = filterByLists[t("Incident")]? filterByLists[t("Incident")].length > 0: false;
    let viewablePlaces;
    let taisakuPlaces;
    let howManyAreLiveStreams = places.filter(place => place.isLiveStream);
    if (incidents) {
      viewablePlaces = places.filter(place => place.isViewable && !place.isLiveStream);
    } else {
      viewablePlaces = places.filter(place => (place.isViewable && !filterByTaisaku && place.taisaku !== "true" && !place.isLiveStream) || (place.isViewable && filterByTaisaku && place.taisaku === "true" && !place.isLiveStream));
      taisakuPlaces = places.filter(place => place.isViewable && place.taisaku === "true" && !place.isLiveStream)
    }
    if (viewablePlaces) {
      this.setState({ 
        placeAmount: viewablePlaces.length,
        taisakuAmount: incidents? 0 : taisakuPlaces? taisakuPlaces.length: 0
      });
    }
  };

  getPhotoData = async (placeId, binaries) => {
    if (binaries.length === 0) return null;
    const photoData = [];
    for (const binary of binaries) {
      if (binary.includes("photo")) {
        photoData.push(geocore.objects.bins.url(placeId, binary));
      }
    }
    return Promise.all(photoData);
  };

  getPhotoURLs = async placeId => {
    // need to add param quality="low"
    const binaries = await geocore.objects.bins.listWithQuality(placeId, 'low');
    const photoData = await this.getPhotoData(placeId, binaries);

    if (!photoData) {
      return { photoURLs: [], binaries };
    }
    const photoURLs = photoData.map(photo => photo.url);

    return { photoURLs, binaries };
  };

  getLiveStreamImagesAndVideo = async (place,hasStorage) => {

    if(place.customData["video_screen_key"] && place.customData["video_storage_key"]) {
      if(hasStorage) {
        try {
          var storageOptions = {
            headers: { 'x-api-key': 'WpgpS6HQ9kavhgoYlquz8RomP3smVsi22WZSe5j9', 'content-type': 'application/json'},
            params: {
              video_screen_key: place.customData["video_screen_key"],
              video_storage_key: place.customData["video_storage_key"]
            }
          }
          var signedUrls = await axios.get("https://7ctaaapag7.execute-api.ap-northeast-1.amazonaws.com/default/openvidu-get-storage-screenshot-and-video", storageOptions);
          if(signedUrls && signedUrls.data && signedUrls.data.video_screen_url && signedUrls.data.video_storage_url) {
            return ({
              video_screen_url: signedUrls.data.video_screen_url,
              video_storage_url: signedUrls.data.video_storage_url,
            })
          } else {
            return ({
              video_screen_url: null,
              video_storage_url: null,
            })
          }
        }catch(e) {
          console.log(e);
          return ({
            video_screen_url: null,
            video_storage_url: null,
          })
        }
      }
    } else {
      return ({
        video_screen_url: null,
        video_storage_url: null,
      })
    }
    
    /*
    await axios({
      method: 'POST',
      url: "https://9tmri856jc.execute-api.ap-northeast-1.amazonaws.com/default/getScreenShotHazardLive",
      headers: {
        "x-api-key": "rsTDTrLyEp4vwueszwgPLyRMSOGYBrb4Pem1eVzf",
      },
      data: placeUpdate
    }).then(response => {
      if(response.data && response.data.signed_url) {
        screenshotURL = response.data.signed_url;
      }
    }).catch((error) => {
      console.log(error);
    });

    // this means that the stream has video storage, or incoming video storage
    if(hasStorage) {
      await axios({
        method: 'POST',
        url: "https://gnt3m9ng6d.execute-api.ap-northeast-1.amazonaws.com/default/getHazardLiveVideoStorage",
        headers: {
          "x-api-key": "beOJMBr2QJ4pphxqaU7bmQX3DYqTsHK6mJDdu7ve",
        },
        data: placeUpdate
      }).then(response => {
        if(response.data && response.data.signed_url) {
          videoURL = response.data.signed_url;
        } else {
          videoURL = null;
        }
      }).catch((error) => {
        console.log(error);
      });
    }
    */

  }

  updatePlaceImageUrls = async (place) => {
    let URLs = {};

    let id = place.id || place.placeId;

    const {photoURLs, binaries} = await this.getPhotoURLs(id);

    if (photoURLs) {
      URLs.photoURLs = photoURLs;
    } 

    let hasVideo;
    let videoBinary;
    let info;

    if(binaries) {
      hasVideo = binaries.some(binary => {
        if (binary.startsWith("video.")) {
          videoBinary = binary;
          return true;
        }
        return false;
      });
    };

    if (hasVideo) {
      info = await geocore.objects.bins.url(id, videoBinary)
    }

    if(info) {
      URLs.videoURL = info.url;
    }

    this.setState(prevState => {
      const newPlaces = prevState.places.map(p => {
        if (p.placeId === place.placeId) {
          return {
            ...p,
            images: URLs.photoURLs,
            video: URLs.videoURL
          }
        } else {
          return p;
        }
      });

      return { places: newPlaces };
    });
  };


  getImages = async place => {
    let URLs = {};

    let id = place.id || place.placeId;

    const {photoURLs, binaries} = await this.getPhotoURLs(id);

    if (photoURLs) {
      URLs.photoURLs = photoURLs;
    } 

    let hasVideo;
    let videoBinary;
    let info;

    if(binaries) {
      hasVideo = binaries.some(binary => {
        if (binary.startsWith("video.")) {
          videoBinary = binary;
          return true;
        }
        return false;
      });
    };

    if (hasVideo) {
      info = await geocore.objects.bins.url(id, videoBinary)
    }

    if(info) {
      URLs.videoURL = info.url;
    } 
    
    return URLs;
  };

  filterItems = () => {
    const {t} = this.props;
    const hasHazardLevels = this.state.filterByLists[t("Urgency Level")].length > 0;
    const hasIncidentTags = this.state.filterByLists[t("Incident")].length > 0;
    const hasGroups = this.state.filterByLists[t("Devices")].length > 0;
    const hasCreatedAt = this.state.filterByLists[t("Date Range")].length > 0;
    const hasLastLocation = this.state.filterByLists[t("Device Last Location")].length > 0;
    const hasFacilityInfo = this.state.filterByLists[t("Facility Information")].length > 0;
    const hasComment = this.state.filterByLists[t("Admin Comment")].length > 0;
    const hasUsers = this.state.filterByLists[t("Person")].some(userGroup => Object.values(userGroup)[0].length > 0);

    let filteredPlaces = this.state.places;
    let matchedPlaces = [];


    // take out places that are above the queried amount
    if (filteredPlaces && filteredPlaces.length > 0) {
      let filteredPlacesAmount = filteredPlaces.filter(place => place.index < this.state.allocatedPlaceAmount);
      filteredPlaces = filteredPlacesAmount;

      if (this.state.filterByTaisaku && !this.state.disabledTaisaku) {
        let filteredTaisakuPlaces = filteredPlaces.filter(place => place.taisaku === "true");
        filteredPlaces = filteredTaisakuPlaces;
      } 

      if (hasHazardLevels) {
        let filteredHazardLevelList = filteredPlaces.filter(place => !this.state.filterByLists[t("Urgency Level")].includes(place.hazardLevel.id));
        filteredPlaces = filteredHazardLevelList;
      }
      if (hasIncidentTags) {
        const bounds = new window.google.maps.LatLngBounds();
        let filteredIncidentTags = [];
        filteredPlaces.forEach(place => {

          let matchedTag = place.incidentTags.some(incidentTag => this.state.filterByLists[t("Incident")].includes(incidentTag.id));

          if (matchedTag) {
            this.showIncident(place.placeId);
            bounds.extend({ lat: place.geo.latitude, lng: place.geo.longitude });

            filteredIncidentTags.push(place);
          }

        });

        if (filteredIncidentTags.length > 0) {
          this.state.map.fitBounds(bounds);
        }
        filteredPlaces = filteredIncidentTags;
      }

      if (hasGroups) {
        let filteredGroupsList = filteredPlaces.filter(place => {
          let filteredGroup = this.state.filterByLists[t("Devices")].find(group => group.id === place.groupId);
          return !filteredGroup;
        });
        filteredPlaces = filteredGroupsList;
      }

      if (hasUsers) {
        let groupUserIds = [];
        this.state.filterByLists[t("Person")].forEach(userGroup => {
          if (Object.values(userGroup)[0].length > 0) {
            Object.values(userGroup)[0].forEach(user => groupUserIds.push(user.id));
          }
        });

        let filteredUserList = filteredPlaces.filter(place => {
          let groupUserMatch = groupUserIds.some(groupUserId => place.placeId.includes(groupUserId));
          return !groupUserMatch;
        });

        filteredPlaces = filteredUserList;
      }

      if (hasCreatedAt) {
        let filteredCreatedAtList = filteredPlaces.filter(place => {
          let createdAt = moment(place.createTime, "YYYY/MM/DD HH:mm").unix();
          let startDate = this.state.filterByLists[t("Date Range")][0].startDate;
          let endDate = this.state.filterByLists[t("Date Range")][0].endDate;
          return createdAt >= startDate && createdAt <= endDate;
        });
        filteredPlaces = filteredCreatedAtList;
      }

      this.state.places.forEach(place => {
        if (filteredPlaces.includes(place)) {
          place.isViewable = true;
          matchedPlaces.push(place);
        } else {
          place.isViewable = false;
          matchedPlaces.push(place);
        }
      });
    }

    if (hasComment) {
      this.setAllCommentMarkers();
    }

    if (hasLastLocation) {
      let groups = this.props.groups;

      let filteredUserGroups = groups.filter(userGroup => this.state.filterByLists[t("Device Last Location")].includes(userGroup.id));

      let matchedUsers = [];

      filteredUserGroups.forEach(filteredUserGroup => {
        filteredUserGroup.users.forEach(user => {
          matchedUsers.push(user);
        });
      });

      this.setState({ 
        users: matchedUsers,
        showRealtimeMarkers: false
      }, () => {
        this.setAllLastLocationMarkers();
      });
    }

    if (this.state.filterByLists[t("Device Last Location")].length === 0 && this.state.lastLocationMarkers) {
      this.state.lastLocationMarkers.forEach(marker => {
        marker.setVisible(false);
      });
    }

    if (this.state.filterByLists[t("Admin Comment")].length === 0 && this.state.commentMarkers) {
      this.state.commentMarkers.forEach(marker => {
        marker.setVisible(false);
      });
    }

    if (hasFacilityInfo) {
      let facilityGroups = this.props.facilityGroups;
      let groupIdList = this.state.filterByLists[t("Facility Information")];
      let matchedFacilities = {};

      groupIdList.forEach(groupId => {
        if (facilityGroups[groupId]) {
          matchedFacilities[groupId] = facilityGroups[groupId];
        }
      });

      this.setState({ facilities: matchedFacilities }, () => {
        this.setAllFacilityMarkers();
      });
    }
    
    if (this.state.filterByLists[t("Facility Information")].length === 0 && this.state.facilityMarkers) {
      const facilityMarkers = _.flatten(Object.values(this.state.facilityMarkers));
      facilityMarkers.forEach(facilityMarker => {
        facilityMarker.setVisible(false);
      });
    }

    this.setState({ 
      places: matchedPlaces,
      amountSelected: 0,
      checkboxData: {},
      allChecked: false,
      disabledTaisaku: hasIncidentTags,
      loadingMoreList: false,
      loadingTabSwitch: false,
      loadingPlaces: false, 
    }, () => {
      if (this.state.places && this.state.places.length > 0) {
        //after we have filtered the places we now adjust how many are visiable to the user in the list at once
        this.viewablePlacesAmount();
        //what map markers should be visisble or not
        this.setAllMarkers(matchedPlaces);
        //I think this was for setting the state of the check boxes?
        this.setCheckboxData();
      }
    });
  };

  toggleTaisakuFilters = () => {
    const { markers, places } = this.state;
    this.setState(prevState => ({ 
      filterByTaisaku: !prevState.filterByTaisaku 
    }), () => {
      const { filterByTaisaku } = this.state;

      if (markers) {
        markers.forEach(marker => {
          const markerPlace = places.find(place => place.placeId === marker.placeId);
  
          if (!markerPlace.isLiveStream) {
            if (filterByTaisaku) {
              if (markerPlace.taisaku === "true") { 
                window.mc.addMarker(marker);
                marker.setVisible(true); 
              } else { 
                marker.setVisible(false); 
                window.mc.removeMarker(marker);
              }
            } else {
              if (markerPlace.taisaku === "true") {
                marker.setVisible(false);
                window.mc.removeMarker(marker);
              } else {
                window.mc.addMarker(marker);
                marker.setVisible(true);
              }
            } 
          }
        });
      }
      this.filterItems();
    });
  };

  showIncident = placeId => {
    const { markers } = this.state;

    if (markers) {
      const incidentMarker = markers.find(marker => marker.placeId === placeId);

      if (incidentMarker) {
        incidentMarker.setVisible(true);
      }
    }
  };

  createMarkers = (resetMarkers, newPlacesList) => {
    const { places, map } = this.state;
    const markers = [];

    places.forEach((place) => {
      
      let isDuplicate = false;

      if (newPlacesList && newPlacesList.some(newPlace => newPlace.placeId === place.placeId)) {
        isDuplicate = true;
      }

      let thumbnailImage = place.images? place.images.find((im) => im.includes("low")): null;

      if (isDuplicate) {
        // this is a recent marker that has a different design, will be created later
      } else {
        var colorPin = name => {
          switch (name) {
            case "緊急度4":
              return "blue";
            case "緊急度3":
              return "green";
            case "緊急度2":
              return "yellow";
            case "緊急度1":
              return "red";
            default:
              return "blue";
          }
        };
  
        var isVisible;
        
        if (place.taisaku === "true") {
          if (this.state.filterByTaisaku || this.state.disabledTaisaku) {
            isVisible = true;
          } else {
            isVisible = false;
          }
        } else {
          if (this.state.filterByTaisaku) {
            isVisible = false;
          } else {
            isVisible = true;
          }
        }
  
        if (place.placeId.includes("-LIVE-") && this.state.photoListShow) {
          isVisible = false
        } 

        // some old places have broken hazardlevels so added a fallback
        var HazardLevelIcon = 
        {
          anchor: new window.google.maps.Point(16, 60),
          url: require(`../../images/hazard-level-icon-${place.isSos? "sos":colorPin(place.hazardLevel.name)}.svg`),
          scaledSize: new window.google.maps.Size(32, 60)
        };
  
        const marker = new window.google.maps.Marker({
          position: {
            lat: place.geo.latitude,
            lng: place.geo.longitude
          },
          icon: HazardLevelIcon,
          map,
          visible: isVisible,
          placeId: place.placeId
        });
  
        markers.push(marker);

        const content = `
          <div id="iw-${place.placeId}" class="place-list-marker-infowindow ${place.isSos? "sos-infowindow": "place-infowindow"} animated">
            <div>
              <div>
                <h4>${place.user}</h4>
              </div>
            </div>
          </div>
        `;
  
        const infoWindow = new window.google.maps.InfoWindow({
          content
        });
  
        marker.addListener("click", () => {
          const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);
          if(this.props.isLiveApp) {
            this.handleLiveStreamListClick(place);
          } else {
            this.handleOpen(markerPlace);
          }
        });
  
        marker.addListener('visible_changed', () => {
          if (marker.visible) {
            infoWindow.open(map, marker);
          } else {
            infoWindow.close(map, marker);
          }
        });

        marker.addListener('mouseover', () => {
          const expandedContent = `
            <div id="iw-${place.placeId}-expanded" class="place-list-marker-infowindow-extended ${place.isSos? "sos-infowindow": "place-infowindow"}">
              ${thumbnailImage || place.images? 
                `<div>
                  <img class="image-window" height="100" width="100" src=${
                    thumbnailImage? thumbnailImage: place.images[0]
                  } />
                </div>`
                : ``
              }
              <div>
                <div>
                  <h4>${place.user}</h4>
                </div>
                <div>
                  <h4>${place.createTime}</h4>
                </div>
              </div>
            </div>
          `;

          infoWindow.setContent(expandedContent);
          infoWindow.setZIndex(9999);
        });

        marker.addListener('mouseout', () => {
          infoWindow.setContent(content);
          infoWindow.setZIndex(1);
        });
      }
    });

    if (resetMarkers) {
      // clear and remove all previous markers so no duplicates exist on map
      this.state.markers.forEach(marker => {
        marker.setVisible(false);
        window.mc.removeMarker(marker);
        marker.setMap(null);
      });
    }


    this.setState({ markers, loadingPlaces: false }, () => {    
      //setting ignoreHidden to true means to not include hidden markers (stupid wording google...)
      window.mc = new MarkerClusterer(map, this.state.markers, {
        maxZoom: 15,
        ignoreHidden: true,
        styles: [
          {
            width: 18,
            height: 42,
            zIndex: 2,
            url: HazardIcon,
            anchorText: [-28, -1],
            textSize: 14,
            textColor: "#000",
            fontWeight: "bold"
          },
        ],
        clusterClass: "hazard-cluster-bubble animated"
      });

      if (newPlacesList) {
        this.createRecentMarkers(newPlacesList, this.state.map);
      }
    });
  };

  createMarkerEditPlace = place => {
    const { map, markers } = this.state;
    const {t} = this.props;
    let markerIndex = markers.findIndex(marker => marker.placeId === place.placeId);
    let thumbnailImage = place.images? place.images.find((im) => im.includes("low")): null;

    markers[markerIndex].setVisible(false);
    window.mc.removeMarker(markers[markerIndex]);
    markers[markerIndex].setMap(null);

    var colorPin = name => {
      switch (name) {
        case "緊急度4":
          return "blue";
        case "緊急度3":
          return "green";
        case "緊急度2":
          return "yellow";
        case "緊急度1":
          return "red";
        default:
          return "blue";
      }
    };

    var isVisible;

    if (place.taisaku === "true") {
      if (this.state.filterByTaisaku || this.state.disabledTaisaku) {
        isVisible = true;
      } else {
        isVisible = false;
      }
    } else {
      if (this.state.filterByTaisaku) {
        isVisible = false;
      } else {
        isVisible = true;
      }
    }

    if (this.state.filterByLists[t("Incident")].length > 0) {
      if (place.incidentTags.length > 0) {
        // if they are shown, check if there are matching tags to determine if the place should be viewable
        let matchedTag = place.incidentTags.some(incidentTag => this.state.filterByLists[t("Incident")].includes(incidentTag.id));
        if (matchedTag) {
          // the place should be visible
        } else {
          // no matching tags even though incidents are being show, make this place hidden
          // place with one incident was deleted but marker didnt disappear, need to fix that 
          isVisible = false;
        }
      } else {
        isVisible = false;
      }
    } 

    if (place.placeId.includes("-LIVE-") && this.state.photoListShow) {
      isVisible = false
    } 

    var HazardLevelIcon = 
    {
      anchor: new window.google.maps.Point(16, 60),
      url: require(`../../images/hazard-level-icon-${place.isSos? "sos":colorPin(place.hazardLevel.name)}.svg`),
      scaledSize: new window.google.maps.Size(32, 60)
    };

    const marker = new window.google.maps.Marker({
      position: {
        lat: place.geo.latitude,
        lng: place.geo.longitude
      },
      icon: HazardLevelIcon,
      map,
      visible: isVisible,
      placeId: place.placeId
    });
      
    const content = `
      <div id="iw-${place.placeId}" class="place-list-marker-infowindow animated">
        <div>
          <div>
            <h4>${place.user}</h4>
          </div>
        </div>
      </div>
    `;

    const infoWindow = new window.google.maps.InfoWindow({
      content
    });

    marker.addListener("click", () => {
      const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);
      if(this.props.isLiveApp) {
          this.handleLiveStreamListClick(place);
        } else {
          this.handleOpen(markerPlace);
        }
    });

    if (isVisible) {
      infoWindow.open(map, marker);
    }

    marker.addListener('visible_changed', () => {
      if (marker.visible) {
        infoWindow.open(map, marker);
      } else {
        infoWindow.close(map, marker);
      }
    });

    marker.addListener('mouseover', () => {
      const expandedContent = `
        <div id="iw-${place.placeId}-expanded" class="place-list-marker-infowindow-extended">
          ${thumbnailImage || place.images? 
            `<div>
              <img class="image-window" height="100" width="100" src=${
                thumbnailImage? thumbnailImage: place.images[0]
              } />
            </div>`
            : ``
          }
          <div>
            <div>
              <h4>${place.user}</h4>
            </div>
            <div>
              <h4>${place.createTime}</h4>
            </div>
          </div>
        </div>
      `;

      infoWindow.setContent(expandedContent);
      infoWindow.setZIndex(9999);
    });

    marker.addListener('mouseout', () => {
      infoWindow.setContent(content);
      infoWindow.setZIndex(1);
    });

    this.setState(prevState => ({
      markers: [
        ...prevState.markers.slice(0, markerIndex),
        marker,
        ...prevState.markers.slice(markerIndex + 1)
      ]
    }), () => {
      if (isVisible) {
        window.mc.addMarker(marker);
      }
    });
  };

  createRecentMarkers = (recentPlaces, map) => {
    const recentMarkers = [];
    recentPlaces.forEach(place => {
      var colorPin = name => {
        switch (name) {
          case "緊急度4":
            return "blue";
          case "緊急度3":
            return "green";
          case "緊急度2":
            return "yellow";
          case "緊急度1":
            return "red";
          default:
            // to prevent broken pins!
            return "blue";
        }
      };
      let thumbnailImage = place.images? place.images.find((im) => im.includes("low")): null;

      var isVisible;

      if (place.placeId.includes("-LIVE-") && this.state.photoListShow) {
        isVisible = false
      } else if (place.taisaku === "true" && !this.state.filterByTaisaku) {
        isVisible = false
      } else {
        isVisible = true
      }

      var HazardLevelIcon = 
      {
        anchor: new window.google.maps.Point(16, 60),
        url: require(`../../images/hazard-level-icon-${place.isSos? "sos":colorPin(place.hazardLevel.name)}.svg`),
        scaledSize: new window.google.maps.Size(32, 60)
      };

      const marker = new window.google.maps.Marker({
        position: {
          lat: place.geo.latitude,
          lng: place.geo.longitude
        },
        icon: HazardLevelIcon,
        map,
        visible: isVisible,
        placeId: place.placeId
      });

      recentMarkers.push(marker);
      
      const content = `
        <div id="iw-${place.placeId}" class="place-list-marker-infowindow animated">
          <div>
            <div>
              <h4>${place.user}</h4>
            </div>
          </div>
        </div>
      `;

      const infoWindow = new window.google.maps.InfoWindow({
        content
      });

      marker.addListener("click", () => {
        const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);

        if(this.props.isLiveApp) {
          this.handleLiveStreamListClick(place);
        } else {
          this.handleOpen(markerPlace);
        }
      });

      marker.addListener('visible_changed', () => {
        if (marker.visible) {
          infoWindow.open(map, marker);
        } else {
          infoWindow.close(map, marker);
        }
      });

      marker.addListener('mouseover', () => {
        const expandedContent = `
          <div id="iw-${place.placeId}-expanded" class="place-list-marker-infowindow-extended">
            ${thumbnailImage || place.images? 
              `<div>
                <img class="image-window" height="100" width="100" src=${
                  thumbnailImage? thumbnailImage: place.images[0]
                } />
              </div>`
              : ``
            }
            <div>
              <div>
                <h4>${place.user}</h4>
              </div>
              <div>
                <h4>${place.createTime}</h4>
              </div>
            </div>
          </div>
        `;
  
        infoWindow.setContent(expandedContent);
        infoWindow.setZIndex(9999);
      });
  
      marker.addListener('mouseout', () => {
        infoWindow.setContent(content);
        infoWindow.setZIndex(1);
      });
    });

    const newMarkers = this.state.markers? [...this.state.markers, ...recentMarkers]: recentMarkers;

    this.setState({ 
      markers: newMarkers,
      loadingPlaces: false
    }, () => {
      this.handleBlinkMarkers(recentMarkers);
    });
  };

  handleBlinkMarkers = markers => {
    let blinkCount = 0;
    let colorShift = false;

    this.blinkTimeout(markers, blinkCount, colorShift);
  };

  blinkTimeout = (markers, blinkCount, colorShift) => {
    blinkCount++;

    var colorPin = name => {
      switch (name) {
        case "緊急度4":
          return "blue";
        case "緊急度3":
          return "green";
        case "緊急度2":
          return "yellow";
        case "緊急度1":
          return "red";
        default:
          return "blue";
      }
    };

    var shiftedColorPin = name => {
      switch (name) {
        case "緊急度4":
          return "blue";
        case "緊急度3":
          return "green";
        case "緊急度2":
          return "yellow";
        case "緊急度1":
          return "red";
        default:
          return "blue";
      }
    };

    this.blinking = setTimeout(() => {
      if (colorShift) {
        colorShift = false;
        markers.forEach(marker => {
          const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);

          var HazardLevelIcon = 
          {
            anchor: new window.google.maps.Point(16, 60),
            url: require(`../../images/hazard-level-icon-${markerPlace.isSos? "sos":colorPin(markerPlace.hazardLevel.name)}.svg`),
            scaledSize: new window.google.maps.Size(32, 60)
          };

          marker.setIcon(HazardLevelIcon);
        });
      } else {
        colorShift = true;
        markers.forEach(marker => {
          const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);

          var ShiftedHazardLevelIcon = 
          {
            anchor: new window.google.maps.Point(16, 60),
            url: require(`../../images/hazard-level-icon-shifted-${markerPlace.isSos? "sos":shiftedColorPin(markerPlace.hazardLevel.name)}.svg`),
            scaledSize: new window.google.maps.Size(32, 60)
          };

          marker.setIcon(ShiftedHazardLevelIcon);
        });
      }

      if (blinkCount < 16) {
        this.blinkTimeout(markers, blinkCount, colorShift);
      } else {
        if (colorShift) {
          markers.forEach(marker => {
            const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);

            var HazardLevelIcon = 
            {
              anchor: new window.google.maps.Point(16, 60),
              url: require(`../../images/hazard-level-icon-${markerPlace.isSos? "sos":colorPin(markerPlace.hazardLevel.name)}.svg`),
              scaledSize: new window.google.maps.Size(32, 60)
            };
  
            marker.setIcon(HazardLevelIcon);
          });

        } else {
          window.mc.addMarkers(markers);
        }
        clearTimeout(this.blinking);
      }
      
    }, 500);
  };

  createLastLocationMarkers = (map) => {
    let groups = this.props.groups;
    const {t} = this.props;
    let users = [];

    groups.forEach(group => {
      group.users.forEach(user => {
        users.push(user);
      });
    });

    const lastLocationMarkers = [];

    users.forEach(user => {
      if (user.lastLocation) {
        const utcTime = moment.utc(user.lastLocationTime).toDate();
        const convertedTimeTokyo = moment(utcTime).local().format('YYYY-MM-DD HH:mm:ss');

        const currrentTime = moment(new Date());
        const lastUpdateTime = moment(convertedTimeTokyo);
        const difftmin = currrentTime.diff(lastUpdateTime, 'minutes');
        const difftmonth = currrentTime.diff(lastUpdateTime, 'months');
        const isRealTime = difftmin >= 0 && difftmin <= 6; // 5 minute range
        const showRealtimeMarkers = this.state.showRealtimeMarkers; 
        
        if(difftmonth === 0) {
          // within last month this device has updated

          const isPinStillSelected = this.state.prevSelectedLastLocationMarkerIds? this.state.prevSelectedLastLocationMarkerIds.some(id => user.id === id): false;

          const marker = new window.google.maps.Marker({
            position: {
              lat: user.lastLocation.latitude,
              lng: user.lastLocation.longitude
            },
            map,
            userId: user.id,
            icon: isRealTime && showRealtimeMarkers? RealtimeIcon: LocationIcon,
            visible: isPinStillSelected
          });

          lastLocationMarkers.push(marker);
          const content = `
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center">            
              <h4 style="font-size: 13px">${user.name}</h4>
            </div>
          `;

          const infoWindowPhoto = new window.google.maps.InfoWindow({
            content
          });

          infoWindowPhoto.setOptions({
            disableAutoPan: true
          });

          if (isPinStillSelected) {
            infoWindowPhoto.open(map, marker);
          }

          marker.addListener('visible_changed', () => {
            if (this.state.filterByLists[t("Device Last Location")].length > 0 && marker.visible) {
              infoWindowPhoto.open(map, marker);
            } else {
              infoWindowPhoto.close(map, marker);
            }
          });

          marker.addListener("click", () => {
            const markerUser = this.state.users.find(user => user.id === marker.userId);
            this.handleLastLocationOpen(markerUser);
          });


        } else {
          // don't create the marker because its old
        }

      }
    });

    this.setState({
      users,
      lastLocationMarkers
    });
  };

  createCommentMarkers = () => {
    const { map } = this.state;
    const { comments, t } = this.props;
    const commentMarkers = [];
    comments.forEach(comment => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: comment.point.latitude,
          lng: comment.point.longitude
        },
        map,
        id: comment.id,
        name: comment.name,
        userName: comment.userPlaces[0].pk.user.name,
        createTime: comment.createTime.substring(0,comment.createTime.length - 3),
        description: comment.description,
        icon: CommentIcon,
        visible: false
      });

      commentMarkers.push(marker);

      const content = `
        <div>            
          <h4>${comment.name}</h4>        
        </div>
      `;

      const InfoWindow = new window.google.maps.InfoWindow({
        content
      });

      InfoWindow.setOptions({
        disableAutoPan: true
      });

      marker.addListener('visible_changed', () => {
        if (this.state.filterByLists[t("Admin Comment")].length > 0) {
          InfoWindow.open(map, marker);
        } else {
          InfoWindow.close(map, marker);
        }
      });

      marker.addListener("click", () => {
        this.handleCommentOpen(marker);
      });
    });


    this.setState({
      comments,
      commentMarkers
    });

  };

  setAllMarkers = (places) => {
    const { map, markers, filterByTaisaku, disabledTaisaku, videoListShow, photoListShow } = this.state;
  
    const updateMarkerVisibility = (marker, isVisible) => {
      if (isVisible) {
        marker.setMap(map);
        window.mc.addMarker(marker);
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
        window.mc.removeMarker(marker);
        marker.setMap(null);
      }
    };
  
    markers.forEach((marker) => {
      const markerPlace = places.find(place => place.placeId === marker.placeId);
      
      if (!markerPlace || !markerPlace.isViewable) {
        updateMarkerVisibility(marker, false);
        return;
      }
  
      const isLive = markerPlace.placeId.includes("-LIVE-");
      const matchesFilter = filterByTaisaku ? markerPlace.taisaku === "true" : markerPlace.taisaku !== "true";
  
      if (photoListShow || videoListShow) {
        if (disabledTaisaku || matchesFilter) {
          if (videoListShow && isLive) {
            updateMarkerVisibility(marker, true);
          } else if (photoListShow && !isLive) {
            updateMarkerVisibility(marker, true);
          } else {
            updateMarkerVisibility(marker, false);
          }
        } else {
          updateMarkerVisibility(marker, false);
        }
      } else {
        updateMarkerVisibility(marker, false);
      }
    });
  
    if (window.mc) {
      window.mc.repaint();
    }
  };
  
  setAllLastLocationMarkers = () => {
    let { lastLocationMarkers, users } = this.state;
    if(lastLocationMarkers && lastLocationMarkers.length > 0) {
      lastLocationMarkers.forEach(marker => {
        const matchedUser = users.find(user => marker.userId === user.id);
        // Switch Icon back to Location Icon from Realtime Icon when new Last Location Filter is run
        marker.setIcon(LocationIcon);
        matchedUser? marker.setVisible(true) : marker.setVisible(false);
      });
    }
    this.setState({ lastLocationMarkers });
  };

  setAllCommentMarkers = () => {
    this.state.commentMarkers.forEach(marker => {
      marker.setVisible(true);
    });
  };

  createFacilityInfoMarkers = () => {
    const { map } = this.state;
    const { facilityGroups } = this.props;
    
    if (facilityGroups) {
      let facilityMarkers = {};

      const facilities = _.flatten(Object.values(facilityGroups));

      facilities.forEach(facility => {        
        this.getPhotoURLs(facility.id)
        .then(({ photoURLs, binaries }) => {
            var POIIcon = 
            {
              anchor: new window.google.maps.Point(12, 24),
              url: require(`../../images/poi-icon-${facility.color}.svg`),
              scaledSize: new window.google.maps.Size(24, 24)
            };

            const marker = new window.google.maps.Marker({
              position: {
                lat: facility.point.latitude,
                lng: facility.point.longitude,
              },
              map,
              id: facility.id,
              name: facility.name,
              groupId: facility.groupId,
              groupName: facility.groupName,
              description: facility.description,
              images: photoURLs,
              icon: POIIcon,
              visible: false
            });

            let content;

            content = `
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center">            
                <h4>${facility.name}</h4>
              </div>
            `;
  
            const infoWindow = new window.google.maps.InfoWindow({
              content
            });
  
            marker.addListener('visible_changed', () => {
              if (marker.visible) {
                infoWindow.open(map, marker);
              } else {
                infoWindow.close(map, marker);
              }
            });

            marker.addListener("click", () => {
              this.handleFacilityOpen(marker);
            });
            
            if (Array.isArray(facilityMarkers[facility.groupId])) {
              facilityMarkers[facility.groupId].push(marker);
            } else {
              facilityMarkers[facility.groupId] = []
              facilityMarkers[facility.groupId].push(marker);
            }
          });
      });

      this.setState({ facilityMarkers });
    }
  };
              
  setAllFacilityMarkers = () => {
    const { facilityMarkers } = this.state;
    const {t} = this.props;
    let groupIdList = this.state.filterByLists[t("Facility Information")];

    const markers = _.flatten(Object.values(facilityMarkers));

    markers.forEach(marker => {
      if (groupIdList.includes(marker.groupId)) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
    });
  };

  registerFilterItems = (title, filterByLists, filterByTaisaku) => {

    const {t} = this.props;
    if (title === t("Incident")) {
      // Unset Hazard Level Filters and Time Filters when User Filters by Incidents
      this.setState(
        prevState => ({
          filterByLists: {
            ...prevState.filterByLists,
            [t("Urgency Level")]: [],
            [t("Devices")]: [],
            [t("Date Range")]: [],
            [t("Person")]: [],
            [t("Incident")]: filterByLists
          },
          filterByTaisaku,
          disabledTaisaku: true
        }),
        () => {
          this.filterItems();
        }
      );
    } else {
      this.setState(
        prevState => ({
          filterByLists: {
            ...prevState.filterByLists,
            [title]: filterByLists
          },
          filterByTaisaku,
          disabledTaisaku: false
        }),
        () => {
          this.filterItems();
        }
      );
    }

  };

  setDates = async (startDate, endDate, range) => {
    this.setState({ loadingPlacesByArea: true });
    const {t} = this.props;
    
    //this.clearPlaceListMarkers();

    var groupIds = [];
    this.props.groups.forEach(group => {
      groupIds.push("TAG-TNHP-1-PT-" + group.id);
    });
    const { map } = this.state;

    const places = await queryPlacesDateRange({
      startDate,
      endDate,
      groupIds,
      range,
      map,
      setState: this.setState.bind(this),
    });

    console.log("Places after queryPlacesDateRange completed and back to dashboard: ", places.length);

    this.setState({ markers: null });
    if(window.mc) {
      window.mc.clearMarkers();
    }

    if (places && places.length > 0) {
      const canQueryMore = places && places.length > this.state.allocatedPlaceAmount;

      this.setState({
        canQueryMore,
        pageNumber: 1,
        pagePlaceAmount: 200,
        allocatedPlaceAmount: 50,
      }, () => {
        this.formatListings(places);
      });
    } else {
        this.setState({ 
          hasError: true,
          errorType: "warning",
          errorMessage: t("There are no applicable reports."),
          loadingPlacesByArea: false,
          checkboxData: {},
          allChecked: false,
          amountSelected: 0,
          placeAmount: 0,
          taisakuAmount: 0,
          places: [],
          canQueryMore: false,
          pageNumber: 1,
          pagePlaceAmount: 0,
          allocatedPlaceAmount: 0
        });
    }
  };

  handleOpen = async place => {
    if (place.images && place.images.length > 0) {
      // place already has an image
      this.setState({ 
        open: true, 
        selectedPlace: place 
      }, () => {
        this.userHasRead(place);
      });
    } else {
      let URLs = await this.getImages(place);
  
      let editedPlace = this.editPlaceImages(place, URLs);

      this.setState({ 
        open: true, 
        selectedPlace: editedPlace 
      }, () => {
        this.userHasRead(editedPlace)
      });
    }
  };

  editPlaceImages = (oldPlace, URLs) => {
    let { places } = this.state;

    let editedPlace = _.cloneDeep(oldPlace);

    editedPlace.images = URLs.photoURLs;
    editedPlace.video = URLs.videoURL;

    let placeIndex = places.findIndex(place => place.placeId === editedPlace.placeId);
    
    this.setState(prevState => ({
      places: [
        ...prevState.places.slice(0, placeIndex),
        editedPlace,
        ...prevState.places.slice(placeIndex + 1)
      ]
    }));

    return editedPlace;
  };

  handleOpenLiveStream = place => {
    if(this.state.openLiveStream) {
      this.setState({unmountLoading: true});
      document.getElementById("livestream_detail_window").click();
      var timeThrottle = setTimeout(() => {
        this.setState({ openLiveStream: true, selectedLivePlace: place });
        //prevent map flash
        var timeInception = setTimeout(() => {
          this.setState({unmountLoading: false});
          clearTimeout(timeThrottle);
          clearTimeout(timeInception);
        }, 500);
      }, 1000);

    } else {
      this.setState({ openLiveStream: true, selectedLivePlace: place });
    }
  };

  handleOpenLiveStorage =  (place) => {
    if(this.state.openLiveStorage) {
      this.setState({unmountLoading: true});
      document.getElementById("livestream_detail_window").click();
      var timeThrottle = setTimeout(() => {
        this.setState({ openLiveStorage: true, selectedPlace: place });
        //prevent map flash
        var timeInception = setTimeout(() => {
          this.setState({unmountLoading: false});
          clearTimeout(timeThrottle);
          clearTimeout(timeInception);
        }, 500);
      }, 1000);

    } else {
      this.setState({ openLiveStorage: true, selectedPlace: place });
    }
  }

  handleEditPlaceOpen = () => this.setState({ editPlaceOpen: true });

  handleLastLocationOpen = lastLocation => {
    this.setState({ 
      openLastLocation: true, 
      selectedLastLocation: lastLocation 
    });
  };

  handleCommentOpen = comment => {
    this.setState({ 
      openComment: true, 
      selectedComment: comment 
    });
  };
  
  handleFacilityOpen = facility => {
    this.setState({ 
      openFacility: true, 
      selectedFacility: facility 
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      openLastLocation: false,
      openFacility: false,
      openComment: false,
      openLiveStorage: false,
      openLiveStream: false,
      isWebRTC: false,
    });
  };

  handleEditPlaceClose = () => this.setState({ editPlaceOpen: false });

  viewableIncidentTags = (incidentTags) => {
    // check if incidents are currently being shown
    const {t} = this.props;
    if (this.state.filterByLists[t("Incident")].length > 0) {
      if (incidentTags.length > 0) {
        // if they are shown, check if there are matching tags to determine if the place should be viewable
        let matchedTag = incidentTags.some(incidentTag => this.state.filterByLists[t("Incident")].includes(incidentTag.id));
        if (matchedTag) {
          return true;
        } else {
          // no matching tags even though incidents are being show, make this place hidden
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  updatePlace = async (updatedPlace, incidentTagsList, updatedHazardLevel) => {
    const { parentGroup, geocore } = this.props;
    const { places } = this.state;

    await updatePlace({
      updatedPlace,
      incidentTagsList,
      updatedHazardLevel,
      parentGroup,
      currentPlaces: places,
      getLiveStreamImagesAndVideo: this.getLiveStreamImagesAndVideo.bind(this),
      getImages: this.getImages.bind(this),
      viewableIncidentTags: this.viewableIncidentTags.bind(this),
      viewablePlacesAmount: this.viewablePlacesAmount.bind(this),
      createMarkerEditPlace: this.createMarkerEditPlace.bind(this),
      map: this.state.map,
      setState: this.setState.bind(this),
    });
  };

  updatePublicPlace = (placeId, isPublic) => {
    let places = [...this.state.places];

    let matchedIndex = places.findIndex(place => place.placeId === placeId);
    places[matchedIndex].isPublic = isPublic;
    places[matchedIndex].updateTime = moment().format("YYYY/MM/DD (ddd) HH:mm");

    let alertMessage = isPublic? "外部公開されました。" : "非公開にされました。";

    this.setState({ 
      loadingPublicPlace: false, 
      hasError: true, 
      errorMessage: alertMessage,
      errorType: "success"
    }, () => {
      this.handlePublicOpen();
    });
  };

  handlePublicPlace = () => {
    this.setState({ loadingPublicPlace: true });

    const selectedPlace = this.state.selectedPlace;
    const shouldBePublic = !selectedPlace.isPublic;
    const placeId = selectedPlace.placeId;

    const placeUpdate = {
      customData: {
        "isPublic": `${shouldBePublic}`
      }
    };    

    shouldBePublic? this.updatePlacePublicDB(placeId, placeUpdate) : this.removePlacePublicDB(placeId, placeUpdate);
  };

  updatePlacePublicDB = (placeId, placeUpdate) => {
    // First, update and get unformatted place data from Geocore
    geocore.places.update(placeId, placeUpdate)
      .then(updatedPlace => {
        axios({
          method: 'POST',
          url: 'https://hazardalert.geocore.jp:4000/places',
          data: updatedPlace
        })
          .then(() => {
            this.updatePublicPlace(placeId, true);
          })
          .catch(error => {
            // Roll back Geocore update here
            const selectedPlace = this.state.selectedPlace;
            const isPublic = selectedPlace.isPublic;

            const rollbackUpdate = {
              customData: {
                "isPublic": isPublic
              }
            };

            geocore.places.update(placeId, rollbackUpdate)
              .catch(error => {
                console.log(error);
              });
          });
      })
      .catch(error => {
        // Display Alert
        console.log(error);
      });
  };

  getPlacesPublicDB = () => {
    // When user click the 公開 tab, pull public places from PublicDB
    const { t } = this.props;

    axios({
      method: 'GET',
      url: 'https://hazardalert.geocore.jp:4000/places',
    })
    .then(response => {
      // Format and Filter Items with Places pulled from the PublicDB
      let publicPlaces = response.data.places;
      if (publicPlaces && publicPlaces.length > 0) {
        let filteredPlaces = filterDeleted(publicPlaces);
        if (filteredPlaces && filteredPlaces.length > 0) {
          this.formatListings(filteredPlaces);
        } else {
          this.setState({
            hasError: true,
            loadingMoreList: false,
            loadingTabSwitch: false,
            canQueryMore: false,
            places: null,
            placeAmount: 0,
            taisakuAmount: 0,
            checkboxData: {},
            amountSelected: 0,
            pageNumber: 1,
            allocatedPlaceAmount: 50,
            pagePlaceAmount: 200,
            errorType: "warning",
            errorMessage: t("No more reports found"),
            publicListEmptyAlert: true
          });
        }
      } else {
        this.setState({
          hasError: true,
          loadingMoreList: false,
          loadingTabSwitch: false,
          canQueryMore: false,
          places: null,
          placeAmount: 0,
          taisakuAmount: 0,
          checkboxData: {},
          amountSelected: 0,
          pageNumber: 1,
          allocatedPlaceAmount: 50,
          pagePlaceAmount: 200,
          errorType: "warning",
          errorMessage: t("No more reports found"),
          publicListEmptyAlert: true
        });
      }
    })
    .catch(error => {
      // An error occured with Axios call to PublicDB
      this.setState({ 
        hasError: true, 
        errorType: "error",
        errorMessage: t("An error has occurred. Please try again."),
        loadingTabSwitch: false 
      });
    });
  };

  removePlacePublicDB = (placeId, placeUpdate) => {
    // First, update Geocore and then remove public place
    geocore.places.update(placeId, placeUpdate)
      .then(() => {
        axios({
          method: 'DELETE',
          url: `https://hazardalert.geocore.jp:4000/places/${placeId}`,
        })
          .then(() => {
            this.updatePublicPlace(placeId, false);
          })
          .catch(error => {
            console.log(error);
            // Roll back Geocore update here
            const selectedPlace = this.state.selectedPlace;
            const isPublic = selectedPlace.isPublic;

            const rollbackUpdate = {
              customData: {
                "isPublic": isPublic
              }
            };

            geocore.places.update(placeId, rollbackUpdate)
              .catch(error => {
                console.log(error);
              });
          });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handlePlaceListClick = place => {
    this.handleOpen(place);
  };

  toggleMarkerBounce = (hoveredMarker, placeId, shouldBounce) => {
    hoveredMarker.setAnimation(shouldBounce? window.google.maps.Animation.BOUNCE: null);

    
    const infoWindowDiv = document.getElementById(`iw-${placeId}`);
    
    if (infoWindowDiv) {
      const infoWindowContainer = this.getInfoWindowContainer(infoWindowDiv, "gm-style-iw-a");

      if (shouldBounce) {
        infoWindowContainer.classList.add("animated-bubble", "bounce-bubble");
      } else {
        infoWindowContainer.classList.remove("animated-bubble", "bounce-bubble");
      }
    }
  
    const clusters = window.mc.getClusters();

    clusters.forEach(cluster => {
      const clusterMarkers = cluster.getMarkers();

      if (clusterMarkers && clusterMarkers.length > 1) {
        const hasMarker = clusterMarkers.find(marker => marker.placeId === placeId);

        if (hasMarker) {
          const clusterIconDiv = cluster.clusterIcon_.div_;

          shouldBounce? clusterIconDiv.classList.add("bounce") : clusterIconDiv.classList.remove("bounce");
        }
      }
    });
  };

  handleLiveListHover = (place, shouldBounce) => {
    this.setState({
      hoveredLivePlace: {
        place,
        shouldBounce
      }
    });
  };

  handlePlaceListHover = (e, place, shouldBounce) => {
    const hoveredMarker = this.state.markers.find(marker => marker.placeId === place.placeId);
    if (hoveredMarker) {
      this.toggleMarkerBounce(hoveredMarker, place.placeId, shouldBounce);
    }
  };

  getInfoWindowContainer = (infoWindow, className) => {
    while (infoWindow && infoWindow.parentNode) {
      infoWindow = infoWindow.parentNode;
      if (infoWindow.className.includes(className)) {
        return infoWindow;
      }
    }

    return null;
  };


  handleLiveStreamListClick = place => {
    if(place && place.video) {
      //this is storage
      this.handleOpenLiveStorage(place);
    } else {
      //currently live stream
      this.handleOpenLiveStream(place);
    }
  }

  updatePhoto = (selectedPlace, updatedUrl) => {
    let places = this.state.places;

    let matchedPlace = places.find(place => {
      return place.placeId === selectedPlace.placeId
    });

    places[matchedPlace.index].images[0] = updatedUrl
    
    this.setState({ places });
  };

  handleCreatePlace = async () => {
    this.setState({ createPlaceLoading: true });
    const { t } = this.props;

    try {
      const { selectedPlace } = this.state;
  
      const groupIds = [];
      const tagIds = [];

      // Get unformatted place
      const rawPlace = await geocore.places.get(selectedPlace.placeId);
      
      // Selected Place's User
      const selectedUser = rawPlace.userPlaces[0].pk.user;
      const selectedUserId = selectedUser.id;
  
      // Get users group data
      const usersGroups = await geocore.users.groups(selectedUserId);

      // Assign correct group to their level C (Company) or G (Group)
      let parentGroup;
      let group;

      usersGroups.forEach(data => {
        let tagLetter = data.pk.group.id.split("-")[3];
        if (tagLetter === "C") {
          parentGroup = data.pk.group;
        } else if (tagLetter === "G") {
          group = data.pk.group;
        }
      });

      groupIds.push(parentGroup.id, group.id); 

      // Rebuild ID from LIVE to PLACE

      const timestamp = Date.now();
      const newPlaceId = `PLA-TNHP-1-${selectedUserId}-${timestamp}`;
  
      let newPlaceData = {
        id: newPlaceId,
        name: newPlaceId,
        point: {
          latitude: rawPlace.point.latitude,
          longitude: rawPlace.point.longitude
        },
        customData: {
          "city.group.name": parentGroup.name,
          "group.name": group.name,
          "user.name": selectedUser.name,
          "comment.1": t("This image was created from a LiveGO! video.")
        }
      };

      const tags = rawPlace.tags;
      let tagSevId;
      let tagGroupId;

      tags.forEach(tag => {
        let tagLetters = tag.id.split("-")[3]
        if (tagLetters === "SEV") {
          tagSevId = tag.id;
        } else if (tagLetters === "PT") {
          tagGroupId = tag.id;
        }
      });

      tagIds.push(tagSevId, tagGroupId); 


      geocore.places.tags.addById = function(id, tagIds) {
        return geocore.post('/places/' + id + '/tags?tag_ids=' + encodeURIComponent(tagIds.join(',')), null);
      };
  
      const newPlace = await geocore.places.add(newPlaceData, groupIds);

      await geocore.places.tags.addById(newPlace.id, tagIds);

      this.handleVideoSnapshot(false, newPlace);

    } catch (err) {
      this.setState({ 
        hasError: true, 
        errorType: "error",
        errorMessage: t("An error has occurred. Please try again."),
        createPlaceLoading: false 
      });
    }
  };

  handleSnapshotOpen = () => {
    var currentVideo = document.getElementsByClassName("storage-video")[0];

    if (currentVideo.currentTime > 0) {
      currentVideo.pause();
    }

    this.setState({ snapshotOpen: true });
  };

  handleSnapshotClose = () => {
    this.setState({ snapshotOpen: false });
  };

  handleVideoSnapshot = async (download, newPlace) => {
    // Grab embedded video
    var currentVideo = document.getElementsByClassName("storage-video")[0];
    const { t } = this.props;

    // Recreate video and take snapshot at current time of embedded video
    var video = document.createElement("video");
    video.id = "remade-video";
    video.src = this.state.selectedPlace.video;
    video.crossOrigin = "anonymous";
    video.currentTime = currentVideo.currentTime;

    await video.play();

    video.pause();

    // Create canvas and get current frame
    var canvas = document.createElement("canvas");
    canvas.width = currentVideo.videoWidth;
    canvas.height = currentVideo.videoHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Binary Conversion
    var image = canvas.toDataURL("image/jpeg");
    var data = atob(image.substring("data:image/jpeg;base64,".length));
    var asArray = new Uint8Array(data.length);
    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }

    // Blob Creation
    var f = new Blob([asArray.buffer], {
      type: "application/octet-stream"
    });
    
    if (download) {
      // Create Link Element for Download
      var a = document.createElement("a");
      window.URL = window.URL || window.webkitURL;

      a.href = window.URL.createObjectURL(f);
      a.id = "video-snapshot";
      a.download = "screenshot.png";

      // Download Snapshot
      document.body.appendChild(a);
      a.click();

      // Remove Temp Link
      document.body.removeChild(document.body.lastElementChild);
      this.handleSnapshotClose();
    } else {
      var file = new File([f], "snapshot");
      const addedPhoto = await geocore.objects.bins.upload(newPlace.id, "photo.1", file, "snapshot");

      if (addedPhoto) {
        this.setState({ 
          hasError: true, 
          errorMessage: t("The snapshot has been saved successfully to Hazardview."),
          errorType: "success",
          createPlaceLoading: false,
          snapshotOpen: false
        });
      }
    }
  };

  handleScreenBuilderOpen = () => {
    this.setState(prevState => ({
      screenBuilderOpen: !prevState.screenBuilderOpen,
    }));
  };

  handleRealtimeMapBuilderOpen = () => {
    this.setState(prevState => ({
      realtimeMapBuilderOpen: !prevState.realtimeMapBuilderOpen,
    }));
  };

  handleDragStart = () => {
    this.setState(prevState => ({
      isDragging: true,
    }));
  };

  handleDragStop = (location, place) => {
    this.setState( prevState => ({
      isDragging: false,
      dragEnd: location,
      draggedPlace: place,
    }));
  };

  handleClearDrag = () => {
    this.setState({
      dragEnd: null,
      draggedPlace: null,
    })
  };

  handleOpenSidebar = () => {
    this.setState(prevState => ({
      openSidebar: !prevState.openSidebar,
    }));
  };

  handleToggleRealtimeMapSidebar = () => {
    this.setState(prevState => ({
      openRealtimeMapSidebar: !prevState.openRealtimeMapSidebar,
    }));
  };

  handlePropOpen = () => {
    if(!this.state.openSidebar) {
      this.setState({openSidebar: true});
    }
  };

  handleOpenRealtimeMapSidebar = () => {
    if(!this.state.openRealtimeMapSidebar) {
      this.setState({openRealtimeMapSidebar: true});
    }
  };

  handleClickActive = currentButton => {
    const { loadingPlaces } = this.state;

    if (!loadingPlaces) {

      this.setState({
        activeButton: currentButton,
      });
    
      this.handleActivePlaceList(currentButton);
    }
  };

  handleIsWebRTC = (roomId) => {
    this.joinRoom(roomId);
    this.setState({
      isWebRTC: true,
      openLiveStream: true,
      webRTCRoomId: roomId,
    });
  };

  handleIsHazardCam = (roomId) => {
    this.joinRoomHazardCam(roomId);

    this.hazardCamSocket.on('roomInfo', (data) => {
      if(data && data[0]) {
        this.setState({hazardCamRoomInfo: data[0]});
      }
    });

    this.hazardCamSocket.on('videoStreamData', (data) => {
      if(this.state.player) {
        this.state.player.feed({
           video: new Uint8Array(data.buffer)
        });
      }
    });

    this.setState({
      openHazardCamStream: true,
      hazardCamRoomId: roomId
    });
  }

  setupHCStream = (ref) => {
    if(ref && !this.state.loadingStream) {
      this.setState({
        loadingStream: true,
        player: new window.JMuxer({
            node: ref,
            mode: 'video',
            flushingTime: 1000,
            fps: 15,
            debug: false,
            onError: function(data) {
              console.log('Buffer error encountered', data);
            }
        })
      });
    }
  }

  handleCloseHazardCamStream = () => {

    // leave room
    if(this.state.player) {
      this.state.player.destroy();
    }

    this.leaveRoomHazardCam(this.state.hazardCamRoomId);
    this.hazardCamSocket.off('videoStreamData');
    this.setState({
      openHazardCamStream: false,
      hazardCamRoomId: null,
      hazardCamRoomInfo: null,
      player: null,
    }, () => {
      this.timeClose = setTimeout(() => {
        clearTimeout(this.timeClose);
        this.setState({loadingStream: false})
      }, 1000);
    });
  };

  joinRoomHazardCam = (roomId) => {
    if(this.hazardCamSocket) {
      this.hazardCamSocket.emit('joinRoomViewer', roomId);
    }
  };

  leaveRoomHazardCam = (roomId) => {
    if(this.hazardCamSocket) {
      this.hazardCamSocket.emit('leaveRoomViewer', roomId);
    }
  }

  handlePublicOpen = () => {
    this.setState(prevState => ({ isPublicOpen: !prevState.isPublicOpen }));
  };

  handleErrorAlertClick = () => {
    this.setState({ hasError: false });
  };

  placeListTabSwitch = tabValue => {
    const isPublic = tabValue === 1;
    const currentTab = isPublic? "public" : "all";

    this.setState({ currentTab }, () => {
      this.setState({ loadingTabSwitch: true }, () => {
        // Remove previous markers before switching between place types
        this.clearPlaceListMarkers();
        // Pull from either Geocore or PublicDB depending on the tab
        // Tab 0 = Geocore, Tab 1 = PublicDB
        isPublic? this.getPlacesPublicDB() : this.getPlacesByArea();
      });
    });
  };

  handleCheckbox = placeId => e => {
    e.stopPropagation();

    this.setState({ 
      checkboxData: { ...this.state.checkboxData, [placeId]: e.target.checked }
    }, () => {
      this.getAmountSelected();
      this.hasAllChecked();
    });
  };

  setCheckboxData = () => {
    const { places, filterByTaisaku, filterByLists } = this.state;
    let checkboxData = {};
    const {t} = this.props;
    places.forEach(place => {
      if(
          (place.isViewable && !filterByTaisaku && place.taisaku !== "true") ||
          (place.isViewable && filterByTaisaku && place.taisaku === "true") || 
          (place.isViewable && filterByLists[t("Incident")].length > 0) 
        )
      {
        checkboxData[place.placeId] = false
      }
    });

    this.setState({ checkboxData });
  };

  handleCheckAll = e => {
    const {t} = this.props;
    const { filterByTaisaku, places, filterByLists } = this.state;
    const incidents = filterByLists[t("Incident")].length > 0;
    
    const placeIds = [];

    if (incidents) {
      places.forEach(place => {
        if ((place.isViewable))
          placeIds.push(place.placeId);
      });
    } else {
      places.forEach(place => {
        if ((place.isViewable && !filterByTaisaku && place.taisaku !== "true") || 
            (place.isViewable && filterByTaisaku && place.taisaku === "true"))
          placeIds.push(place.placeId);
      });
    }

    const checkboxData = {};

    const checked = e.target.checked;

    placeIds.forEach(id => {
      checkboxData[id] = checked;
    });

    this.setState({ checkboxData, allChecked: e.target.checked }, () => {
      this.getAmountSelected();
    });
  };

  hasAllChecked = () => {
    const { checkboxData } = this.state;
    const values = Object.values(checkboxData);
    const hasUnChecked = values.includes(false);

    this.setState({ allChecked: !hasUnChecked });
  };
  

  handleMakeTaisaku = async () => {
    const { t } = this.props;

    try {
      this.setState({ loadingAction: true });

      const checkboxData = Object.assign({}, this.state.checkboxData);
      const placeIds = Object.keys(checkboxData);
  
      const makeTaisakuPromises = [];
      const makeTaisakuPlaceIds = [];
      const taisakuUpdate = {
        customData: {
          taisaku: "true"
        }
      };
      // Iterate over places, if they are checked, update Geocore with Taisaku
      placeIds.forEach(id => {
        if (checkboxData[id]) {
          makeTaisakuPlaceIds.push(id);
          const makeTaisakuPromise = geocore.places.update(id, taisakuUpdate);
          makeTaisakuPromises.push(makeTaisakuPromise);
        }
      });
  
      await Promise.all(makeTaisakuPromises);

      this.setState({
        errorType: "success",
        hasError: true,
        errorMessage: t("Report has been moved to the resolved folder"),
        loadingAction: false,
        selectedOpen: false,
        amountSelected: 0,
        checkboxData: {},
        allChecked: false
      });

      this.makeSelectedTaisaku(makeTaisakuPlaceIds);
    } catch (e) {
      this.setState({ 
        hasError: true, 
        errorType: "error",
        errorMessage: t("An error has occurred. Please try again."),
        loadingAction: false
      });
    }
  };

  makeSelectedTaisaku = (placeIds) => {
    let places = _.cloneDeep(this.state.places);

    places.forEach(place => {
      if(placeIds.includes(place.placeId)) {
        place.taisaku = "true";

        if (this.state.filterByTaisaku || this.state.disabledTaisaku) {
          place.isViewable = true;
        } else {
          place.isViewable = false;
          this.setState(prevState => ({
            placeAmount: prevState.placeAmount - 1,
            taisakuAmount: prevState.taisakuAmount + 1
          }));
        }

        this.createMarkerEditPlace(place);
      }
    });

    this.setState({ places });
  };

  handleMarkAsDeleted = async selectedPlace => {
    this.setState({ loadingDelete: true });
    const { t } = this.props;

    try {
      const markDeleted = {
        customData: {
          "deleted": "true"
        }
      };

      await geocore.places.update(selectedPlace.placeId, markDeleted);

      // separate function for public database
      if (selectedPlace.isPublic) {
        await axios({
          method: 'DELETE',
          url: `https://hazardalert.geocore.jp:4000/places/${selectedPlace.placeId}`
        });
      }


      this.removePlaces([selectedPlace.placeId],  t("The report has been deleted"));
    } catch (err) {
      this.setState({
        hasError: true,
        errorType: "error",
        errorMessage: t("An error has occurred. Please try again."),
        loadingDelete: false
      });
    }
  };

  handleMarkSelectedAsDeleted = () => {
    this.setState({ loadingAction: true });

    const checkboxData = Object.assign({}, this.state.checkboxData);
    const placeIds = Object.keys(checkboxData);

    // Ready promises to mark for deletion in Geocore
    const markDeletePromises = [];
    const markDeletePlaceIds = [];
    const markDeleted = {
      customData: {
        "deleted": "true"
      }
    };

    placeIds.forEach(id => {
      // Find checked places with id
      if (checkboxData[id]) {
        markDeletePlaceIds.push(id);
        const markDeletePromise = geocore.places.update(id, markDeleted);
        markDeletePromises.push(markDeletePromise);
      }
    });

    this.markSelectedForDeletion(markDeletePromises, markDeletePlaceIds);
  };

  markSelectedForDeletion = async (markDeletePromises, placeIds) => {
    const { t } = this.props;

    try {
      await Promise.all(markDeletePromises);

      // Remove Deleted Places from Place List and Map
      this.removePlaces(placeIds,  t("Report has been deleted"));
    } catch(err) {
      this.setState({
        hasError: true,
        errorType: "error",
        errorMessage: t("An error has occurred. Please try again.")
      });
    }
  };

  removePlaces = (placeIds, errorMessage) => {

    const { places } = this.state;

    let deletedTaisakuAmount = 0;
    let deletedPlaceAmount = 0;
    let filteredDeletedPlaces = [];

    places.forEach(place => {
      if (placeIds.includes(place.placeId)) {
        if (!place.placeId.includes("-LIVE-")) {
          if (place.taisaku === "true") {
            deletedTaisakuAmount++;
          }
          deletedPlaceAmount++;
        }
      } else {
        filteredDeletedPlaces = filteredDeletedPlaces.concat(place);
      }
    });
    
    const filteredMarkers = this.clearDeletedPlaceMarkers(placeIds);

    this.setState(prevState => ({
      places: filteredDeletedPlaces,
      markers: filteredMarkers,
      amountSelected: 0,
      loadingAction: false,
      selectedOpen: false,
      placeAmount: prevState.placeAmount - deletedPlaceAmount,
      taisakuAmount: prevState.taisakuAmount - deletedTaisakuAmount,
      checkboxData: {},
      allChecked: false,
      loadingDelete: false,
      editPlaceDeleteOpen: false,
      editPlaceOpen: false,
      openLiveStream: false,
      open: false
    }));

    if (errorMessage) {
      this.setState({
        errorType: "success",
        hasError: true,
        errorMessage
      });
    }
  };

  clearDeletedPlaceMarkers = placeIds => {
    let newMarkers = [];
    this.state.markers.forEach(marker => {
      const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);

      if(placeIds.includes(markerPlace.placeId)) {
        // remove from marker clusters first
        marker.setVisible(false);
        window.mc.removeMarker(marker);
        marker.setMap(null);
      } else {
        newMarkers = newMarkers.concat(marker);
      }
    });

    // reset clusters trigger redraw
    if(window.mc) {
      window.mc.repaint();
    }

    return newMarkers
  };

  handlePrintSelected = async () => {
    const { t } = this.props;

    try {
      this.setState({ loadingAction: true, printingPlaceList: []});
  
      let checkboxData = Object.assign({}, this.state.checkboxData);
      const placeIds = Object.keys(checkboxData);
  
      let places = _.cloneDeep(this.state.places);
  
      let printingPlaceListPromises = [];
  
      const printingPlaceAmount = placeIds.length;

      placeIds.forEach(id => {
        if(checkboxData[id]) {
          let printingPlace = places.find(place => place.placeId === id);
          printingPlaceListPromises.push(this.buildPrintingPlace(printingPlace, printingPlaceAmount));
        };
      });
  
      const printingPlaceList = await Promise.all(printingPlaceListPromises);
  
      this.setState({
        printingPlaceList,
        amountSelected: 0,
        checkboxData: {},
        allChecked: false,
        actionProgress: {
          complete: printingPlaceAmount,
          total: printingPlaceAmount
        }
      });
    } catch (err) {
      this.setState({
        errorType: "error",
        hasError: true,
        errorMessage: t("Could not print. Please try again."),
        loadingAction: false
      });
    }
  };

  buildPrintingPlace = async (printingPlace, printingPlaceAmount) => {
    const binaries = await geocore.objects.bins.list(printingPlace.placeId);
    const photoData = await this.getPhotoData(printingPlace.placeId, binaries);

    if(photoData) {
      const printingPlaceURLs = [];
      photoData.forEach(photo => {
        printingPlaceURLs.push(photo.url);
      });
      printingPlace.images.push(printingPlaceURLs);
    }

    this.setState({
      actionProgress: {
        complete: this.state.actionProgress.complete + 1,
        total: printingPlaceAmount
      }
    });

    return printingPlace;
  };

  handleExportSelected = () => {
    const { t } = this.props;

    try {
      this.setState({ loadingAction: true });
  
      let checkboxData = Object.assign({}, this.state.checkboxData);
      const placeIds = Object.keys(checkboxData);
  
      let places = _.cloneDeep(this.state.places);
  
      let placesToExport = [];
  
      placeIds.forEach(id => {
        if(checkboxData[id]) {
          const checkedPlace = places.find(place => place.placeId === id);

          const geoPlace = {
            id: checkedPlace.placeId,
            lat: checkedPlace.geo.latitude,
            lng: checkedPlace.geo.longitude,
            timestamp: new Date(checkedPlace.createTime).getTime()
          };

          placesToExport.push(geoPlace);
        };
      });
    
      this.setState({
        amountSelected: 0,
        checkboxData: {},
        allChecked: false,
        actionProgress: {
          complete: 1,
          total: 1
        }
      });

      downloadKml(placesToExport);

    } catch (err) {
      console.log(err);
      this.setState({
        errorType: "error",
        hasError: true,
        errorMessage: t("Could not download. Please try again."),
        loadingAction: false
      });
    }
  };

  getAmountSelected = () => {
    const checkboxData = Object.assign({}, this.state.checkboxData);
    const selectedValues = Object.values(checkboxData);

    let selectedCounter = 0;

    selectedValues.forEach(value => {
      if (value) selectedCounter++;
    });

    this.setState({ amountSelected: selectedCounter });
  };

  handleSelectedOpen = selectedAction => {
    //this can be: 'taisaku', 'delete', 'incident', or 'print' or 'none' if no places were checked

    const hasCheckedPlace = this.findCheckedPlace();

    if (hasCheckedPlace) {
      // Open Incident Group Form if Incident is the selectedAction 
      if (selectedAction === "incident") {
        this.setState({
          selectedAction,
          incidentTagFormOpen: true,
        });
      } else {
        this.setState({ 
          selectedAction, 
          selectedOpen: true,
          printViewMode: "single"
        });
      }
    } else {
      this.setState({
        selectedAction: 'none',
        selectedOpen: true
      });
    }
  };

  handleSelectedClose = () => {
    this.setState({ 
      selectedAction: null, 
      loadingAction: false, 
      printViewMode: "single", 
      selectedOpen: false, 
      incidentTagFormOpen: false,
      actionProgress: {
        complete: 0,
        total: 0
      }
    });
  };

  handlePrintViewMode = selectedMode => {
    this.setState({ printViewMode: selectedMode });
  };

  updateSettings = (zoomLevel, lat, lng) => {
    this.setState({ loadingAction: true });
    const { t } = this.props;

    const { user } = this.props;

    const userUpdate = {
      customData: {
        initialZoom: `${zoomLevel}`,
        initialLat: `${lat}`,
        initialLng: `${lng}`
      }
    };

    geocore.users.update(user.id, userUpdate)
      .then(updatedUser => {
        this.props.updateUserInfo(updatedUser);
        this.setState({
          errorType: "success",
          hasError: true,
          errorMessage: t("Default settings have been updated."),
          openSettings: false,
          loadingAction: false
        });
        this.state.map.setZoom(zoomLevel);
        this.state.map.setCenter({ lat, lng });
        this.getPlacesByArea();
      })
      .catch(err => {
        this.setState({
          errorType: "error",
          hasError: true,
          errorMessage: t("Default settings were not updated. Please try again."),
          openSettings: false,
          loadingAction: false
        });
      });
  }; 

  restoreDefaults = () => {
    const { user } = this.props;
    // TODO: I don't believe this works as intended, it will use the users default settings, and doesn't change the users default settings
    const initialZoom = user.customData && user.customData.initialZoom? parseInt(user.customData.initialZoom) : 8;
    const initialLat = user.customData && user.customData.initialLat? parseFloat(user.customData.initialLat) : 35.6;
    const initialLng = user.customData && user.customData.initialLng? parseFloat(user.customData.initialLng) : 139.6;
    this.state.map.setZoom(initialZoom);
    this.state.map.setCenter({ lat: initialLat, lng: initialLng });
  };

  handleOpenSettings = () => {
    this.setState(prevState => ({ openSettings: !prevState.openSettings }));
  };

  handleDownloadPlace = (place) => {
    this.setState({ printViewMode: "single", printingPlaceList: [place] }, () => {
      setTimeout(() => {
        const now = new Date();
  
        // Format time in the new format: "yyyyMMddTHHmmss"
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const date = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
  
        const formattedDateTime = `${year}${month}${date}T${hours}${minutes}${seconds}`;
  
        const originalTitle = document.title;
        document.title = `Hazardview_${formattedDateTime}.pdf`;
        window.print();
        document.title = originalTitle; // Reset the title back to original after printing
      }, 2500);
    });
  };
  

  createNewFacilityMarker = (selectedFacilityGroup, facility, photoData) => {
    const { map, facilityMarkers } = this.state;
    const {t} = this.props;
    const groupIds = this.state.filterByLists[t("Facility Information")];

    var POIIcon = 
    {
      anchor: new window.google.maps.Point(12, 24),
      url: require(`../../images/poi-icon-${selectedFacilityGroup.customData.color}.svg`),
      scaledSize: new window.google.maps.Size(24, 24)
    };

    const isVisible = groupIds.includes(selectedFacilityGroup.id);

    const marker = new window.google.maps.Marker({
      position: {
        lat: facility.point.latitude,
        lng: facility.point.longitude,
      },
      map,
      id: facility.id,
      name: facility.name,
      groupId: selectedFacilityGroup.id,
      groupName: selectedFacilityGroup.name,
      description: facility.description,
      images: photoData? [photoData.url] : null,
      icon: POIIcon,
      visible: isVisible
    });

    let content;

    content = `
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center">            
        <h4>${facility.name}</h4>
      </div>
    `;

    const infoWindow = new window.google.maps.InfoWindow({
      content
    });

    if (isVisible) {
      infoWindow.open(map, marker);
    }

    marker.addListener('visible_changed', () => {
      if (marker.visible) {
        infoWindow.open(map, marker);
      } else {
        infoWindow.close(map, marker);
      }
    });

    marker.addListener("click", () => {
      this.handleFacilityOpen(marker);
    });
    
    if(facilityMarkers[selectedFacilityGroup.id]) {
      this.setState(prevState => ({
        facilityMarkers: {
          ...prevState.facilityMarkers,
          [selectedFacilityGroup.id]: [
            ...prevState.facilityMarkers[selectedFacilityGroup.id],
            marker
          ]
        }
      }));
    } else {
      this.setState({ 
        facilityMarkers: {
          ...facilityMarkers,
          [selectedFacilityGroup.id]: [
            marker
          ]
        }
      })
    }
  };

  handleMapStyle = style => {
    this.setDefaultMapStyle(style);
  };

  setDefaultMapStyle = mapStyle => {
    const { user } = this.props;

    const userUpdate = {
      customData: {
        "mapStyle": `${mapStyle}`
      }
    };

    geocore.users.update(user.id, userUpdate)
      .then(updatedUser => this.props.updateUserInfo(updatedUser))
      .catch(err => console.log(err));
  };

  runHeartbeatUrl = () => {
    if(this.heartbeatUrl) {
      clearTimeout(this.heartbeatUrl);
    }
    this.heartbeatUrl = setTimeout(() => {
      if(this.state.places && this.state.places.length > 0) {
        this.state.places.forEach(place => {
          this.updatePlaceImageUrls(place);
        });
      };
      this.runHeartbeatUrl();
    }, 300000);//5 minutes = 300000
  }

  alertPushNotification = (place) => {
    if(!place || place.length === 0 || this.props.isLiveApp) return;
    const {t} = this.props;

    var placeIsLiveStream = place[0].id.includes("-LIVE-");
    if (placeIsLiveStream) return;

    var placeCreateTime = new Date(place[0].createTime).getTime();
    var currentFetchedTime = this.state.mostRecentPlaceTimestamp;

    if (currentFetchedTime && currentFetchedTime < placeCreateTime) {
      console.log("new places available")
      this.setState({
        alertNewPlace: true,
        alertCounter: this.state.alertCounter + 1,
        mostRecentPlaceTimestamp: placeCreateTime
      });

      if (this.state.notificationSound) {
        // Play sound (if the user doesn't interact with the page it will be silent);
        var NewPlaceAlertAudio = new Audio("https://geocore-static.s3.ap-northeast-1.amazonaws.com/short_alert.mp3");
        NewPlaceAlertAudio.play();
      }
      
    }

    //above has to finish within 10s before next place check
    this.runHeartbeat();
  };

  
  runHeartbeat = () => {

    if (this.heartbeat) {
      clearTimeout(this.heartbeat);
    }

    this.heartbeat = setTimeout(() => {
        var groupIds = [];
        this.props.groups.forEach(group => {
          groupIds.push("TAG-TNHP-1-PT-" + group.id);
        });

        const query = new geocore.places.query();
        query.withTagIds(groupIds).withTagDetails().orderByRecentlyCreated().page(1).numberPerPage(1).all().then((place) => {
          this.alertPushNotification(place);
          this.runHeartbeat();
        });
    }, 10000);
  };

  handleUserSettings = () => {
    this.setState(prevState => ({ 
      openUserSettings: !prevState.openUserSettings 
    }));
  };

  updateHazardLevelName = hazardNames => {
    const { t } = this.props;

    this.setState({           
      loadingHazardName: true, 
    });

    const { parentGroup } = this.props;

    const hazardNamesUpdate = {
      customData: hazardNames
    };

    if (_.isEmpty(hazardNames)) {
      this.setState({
        errorType: "error",
        hasError: true,
        errorMessage: t("No tags were edited."),
        loadingHazardName: false
      });
    } else {
      geocore.groups.update(parentGroup.id, hazardNamesUpdate)
        .then(updatedParentGroup => {
          this.props.updateParentGroupInfo(updatedParentGroup);
          if (this.state.places && this.state.places.length > 0) {
            this.updatePlaceListTags(hazardNames);
          }
          this.setState({
            errorType: "success",
            hasError: true,
            errorMessage: t("The name of the tag has been changed."),
            loadingHazardName: false,
            openUserSettings: false
          });
        })
        .catch(error => {
          console.log("Error", error);
          this.setState({
            errorType: "error",
            hasError: true,
            errorMessage: t("An error has occurred. Please try again."),
            loadingHazardName: false
          });
        });
    }
  };


  closeHazardCamStream = (camera) => {
    // need to close ws here
    this.setState({
      hazardCamOpen: false,
      hazardCamType: null,
    });
  }

  updatePlaceListTags = hazardNames => {
    let places = _.cloneDeep(this.state.places);

    const levelNames = Object.keys(hazardNames);
    const newNames = Object.values(hazardNames);

    places.forEach(place => {
      if (levelNames.includes(place.hazardLevel.name)) {
        let nameIndex = levelNames.findIndex(levelName => levelName === place.hazardLevel.name);
        if (nameIndex !== -1) place.hazardLevel.customName = newNames[nameIndex];
      }
    });
    
    this.setState({ places });
  };

  hideAlert = () => {
    this.setState({ alertNewPlace: false, alertCounter: 0 });
    this.runHeartbeat();
  };

  addComment = comment => {
    const commentMarker = this.createCommentMarker(comment);

    this.setState({
      comments: [...this.state.comments, comment],
      commentMarkers: [...this.state.commentMarkers, commentMarker]
    });
  };

  createCommentMarker = comment => {
    const { map } = this.state;
    const {t} = this.props;
    const hasComment = this.state.filterByLists[t("Admin Comment")].length > 0;

    const marker = new window.google.maps.Marker({
      position: {
        lat: comment.point.latitude,
        lng: comment.point.longitude
      },
      map,
      id: comment.id,
      name: comment.name,
      userName: this.props.user.name,
      createTime: comment.createTime.substring(0,comment.createTime.length - 3),
      description: comment.description,
      customData: comment,
      icon: CommentIcon,
      visible: hasComment
    });

    const content = `
      <div>            
        <h4>${comment.name}</h4>        
      </div>
    `;

    const InfoWindow = new window.google.maps.InfoWindow({
      content
    });

    marker.addListener('visible_changed', () => {
      if (this.state.filterByLists[t("Admin Comment")].length > 0) {
        InfoWindow.open(map, marker);
      } else {
        InfoWindow.close(map, marker);
      }
    });

    marker.addListener("click", () => {
      this.handleCommentOpen(marker);
    });

    if (hasComment) InfoWindow.open(map, marker);

    return marker;
  };

  removeCommentMarker = placeId => {
    const { comments, commentMarkers } = this.state; 

    const commentMarkerIndex = commentMarkers.findIndex(commentMarker => commentMarker.id === placeId);
    const commentIndex = comments.findIndex(comment => comment.id === placeId);

    commentMarkers[commentMarkerIndex].setMap(null);

    this.setState(prevState => ({ 
      comments: [
        ...prevState.comments.slice(0, commentIndex),
        ...prevState.comments.slice(commentIndex + 1)
      ],
      commentMarkers: [
        ...prevState.commentMarkers.slice(0, commentMarkerIndex),
        ...prevState.commentMarkers.slice(commentMarkerIndex + 1)
      ],
    }));
  };

  editComment = comment => {
    const { commentMarkers, comments } = this.state;

    const commentMarkerIndex = commentMarkers.findIndex(marker => marker.id === comment.id);
    commentMarkers[commentMarkerIndex].setMap(null);
    
    const commentIndex = comments.findIndex(prevComment => prevComment.id === comment.id);

    const editedCommentMarker = this.createCommentMarker(comment);

    this.setState(prevState => ({
      commentMarkers: [
        ...prevState.commentMarkers.slice(0, commentMarkerIndex),
        editedCommentMarker,
        ...prevState.commentMarkers.slice(commentMarkerIndex + 1)
      ],
      comments: [
        ...prevState.comments.slice(0, commentIndex),
        comment,
        ...prevState.comments.slice(commentIndex + 1)
      ]
    }));
  };

  editFacility = (editedFacility, photoURLs, facilityGroup) => {
    const { facilityMarkers } = this.state; 

    const facilityMarkerGroup = facilityMarkers[facilityGroup.id];

    const facilityMarkerIndex = facilityMarkerGroup.findIndex(facilityMarker => facilityMarker.id === editedFacility.id);
    facilityMarkerGroup[facilityMarkerIndex].setMap(null);

    let editedFacilityMarker = this.createFacilityMarker(editedFacility, facilityGroup, photoURLs);

    this.setState(prevState => ({
      facilityMarkers: {
        ...prevState.facilityMarkers,
        [facilityGroup.id]: [
          ...prevState.facilityMarkers[facilityGroup.id].slice(0, facilityMarkerIndex),
          editedFacilityMarker,
          ...prevState.facilityMarkers[facilityGroup.id].slice(facilityMarkerIndex + 1)
        ]
      }
    }));
  };

  createFacilityMarker = (facility, facilityGroup, photoURLs) => {
    const { map } = this.state;
    const {t} = this.props;
    const groupIds = this.state.filterByLists[t("Facility Information")];

    var POIIcon = 
    {
      anchor: new window.google.maps.Point(12, 24),
      url: require(`../../images/poi-icon-${facilityGroup.customData.color}.svg`),
      scaledSize: new window.google.maps.Size(24, 24)
    };

    const isVisible = groupIds.includes(facilityGroup.id);

    const marker = new window.google.maps.Marker({
      position: {
        lat: facility.point.latitude,
        lng: facility.point.longitude,
      },
      map,
      id: facility.id,
      name: facility.name,
      groupId: facilityGroup.id,
      groupName: facilityGroup.name,
      description: facility.description,
      images: photoURLs,
      icon: POIIcon,
      visible: isVisible
    });

    let content;

    content = `
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center">            
        <h4>${facility.name}</h4>
      </div>
    `;

    const infoWindow = new window.google.maps.InfoWindow({
      content
    });

    if (isVisible) {
      infoWindow.open(map, marker);
    }

    marker.addListener('visible_changed', () => {
      if (marker.visible) {
        infoWindow.open(map, marker);
      } else {
        infoWindow.close(map, marker);
      }
    });

    marker.addListener("click", () => {
      this.handleFacilityOpen(marker);
    });
      
    return marker;
  };

  updateIncidentPlaces = (updatedIncidentTag, prevIncidentTag) => {
    
    if (this.state.places && this.state.places.length > 0) {
      const places = _.cloneDeep(this.state.places);

      places.forEach(place => {
        const matchedIncidentTagIndex = place.incidentTags.findIndex((tag) => tag.id === updatedIncidentTag.id);
        if (matchedIncidentTagIndex >= 0) {
          place.incidentTags[matchedIncidentTagIndex] = updatedIncidentTag
        }
      });
  
      this.setState({ places });
    }
    this.props.updateIncidentTag(updatedIncidentTag);
  };

  removeFacilityMarker = (facilityId, facilityGroup) => {    
    const { facilityMarkers } = this.state; 

    const facilityIndex = facilityMarkers[facilityGroup.id].findIndex(facilityMarker => facilityMarker.id === facilityId);
    facilityMarkers[facilityGroup.id][facilityIndex].setMap(null);

     this.setState(prevState => ({
      facilityMarkers: {
        ...prevState.facilityMarkers,
        [facilityGroup.id]: [
          ...prevState.facilityMarkers[facilityGroup.id].slice(0, facilityIndex),
          ...prevState.facilityMarkers[facilityGroup.id].slice(facilityIndex + 1)
        ]
      },
    }));
  };

  findCheckedPlace = () => {
    const { checkboxData } = this.state;

    const hasCheckedPlace = checkboxData? Object.values(checkboxData).find(value => value === true) : false;

    return hasCheckedPlace;
  };

  userHasRead = async clickedPlace => {
    if (clickedPlace.hasRead) {
      // This place has already been read
    } else {
      let places = _.cloneDeep(this.state.places);
      let selectedPlace = _.cloneDeep(this.state.selectedPlace);
      let matchedIndex = places.findIndex(place => place.placeId === clickedPlace.placeId);

      const hasReadUpdate = {
        customData: {
          "hasRead": "true"
        }
      };
  
      try {
        await geocore.places.update(clickedPlace.placeId, hasReadUpdate);

        places[matchedIndex]["hasRead"] = true;
        selectedPlace["hasRead"] = true;

        this.setState({ places, selectedPlace });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleCreateIncidentTag = async (selectedIncidents) => {
    const { t, geocore, getIncidentTags } = this.props;
    const { checkboxData, places } = this.state;

    await handleCreateIncidentTag({
      checkboxData,
      selectedIncidents,
      getIncidentTags,
      handleSelectedClose: this.handleSelectedClose,
      setState: this.setState.bind(this),
      t,
      places,
    });
  };
  geocoreAddIncidentTag = (name, color) => {
    let incidentTag = {
      id: "TAG-TNHP-1-INC-" + this.props.parentGroup.id + "-" + new Date().getTime(),
      name,
      customData: {
        color
      }
    };
    const { t } = this.props;

    geocore.tags
      .add(incidentTag)
      .then(() => {
        this.props.getIncidentTags(true);
        this.setState({ 
          hasError: true,
          errorType: "success",
          errorMessage: t("Incident information has been updated.")
        });
      })
      .catch(e => {
        this.setState({ 
          hasError: true,
          errorType: "error",
          errorMessage: t("An error has occurred. Please try again.")
        });
      }); 
  };

  handleError = errorMessage => {
    this.setState({ 
      hasError: true, 
      errorType: "error",
      errorMessage,
    });
  };

  removePlaceIncident = incidentId => {
    const { markers } = this.state; 
    const places = _.cloneDeep(this.state.places);
    const {t} = this.props;
    if (places) {
      if (this.state.filterByLists[t("Incident")].length > 0) {
        const incidentPlaceIds = [];
    
        places.forEach(place => {
          const findPlaceIndex = place.incidentTags.findIndex((incidentTag) => incidentTag.id === incidentId);
          if(findPlaceIndex >= 0) {
            place.incidentTags.splice(findPlaceIndex, 1);
            place.isViewable = false;
            incidentPlaceIds.push(place.placeId);
          }
        });
  
        markers.forEach(marker => {
          const markerPlace = this.state.places.find(place => place.placeId === marker.placeId);
  
          if (incidentPlaceIds.includes(markerPlace.placeId)) {
            marker.setVisible(false);
          } 
        });
  
        this.setState({ places });
      } else {
  
        places.forEach(place => {
          const findPlaceIndex = place.incidentTags.findIndex((incidentTag) => incidentTag.id === incidentId);
          if(findPlaceIndex >= 0) {
            place.incidentTags.splice(findPlaceIndex, 1);
          }
        });
  
        this.setState({ places });
      }
    }
  };

  updateFacilityMarkers = tag => {
    const { facilityMarkers } = this.state;

    const selectedFacilityMarkers = [...facilityMarkers[tag.id]];

    selectedFacilityMarkers.forEach(facilityMarker => {
      facilityMarker.setMap(null);
    });

    this.createEditedFacilityMarkers(selectedFacilityMarkers, tag);
  };

  removeFacilityMarkers = tagId => {
    const { facilityMarkers } = this.state;

    let selectedFacilityMarkers;

    if (facilityMarkers[tagId]) {
      selectedFacilityMarkers = [...facilityMarkers[tagId]];
  
      selectedFacilityMarkers.forEach(facilityMarker => {
        facilityMarker.setMap(null);
      });

      this.setState(prevState => ({
        facilityMarkers: {
          ...prevState.facilityMarkers,
          [tagId]: []
        }
      }));
    }

    return selectedFacilityMarkers;
  };

  createEditedFacilityMarkers = (selectedFacilityMarkers, tag) => {
    const { map } = this.state;

    let editedFacilityMarkers = [];

    selectedFacilityMarkers.forEach(facilityMarker => {

      var POIIcon = 
        {
          anchor: new window.google.maps.Point(12, 24),
          url: require(`../../images/poi-icon-${tag.customData.color}.svg`),
          scaledSize: new window.google.maps.Size(24, 24)
        };
      
      const marker = new window.google.maps.Marker({
        position: {
          lat: facilityMarker.position.lat(),
          lng: facilityMarker.position.lng(),
        },
        map,
        id: facilityMarker.id,
        name: facilityMarker.name,
        groupId: facilityMarker.groupId,
        groupName: tag.name,
        description: facilityMarker.description,
        images: facilityMarker.images,
        icon: POIIcon,
        visible: facilityMarker.visible
      });

      let content;

      content = `
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center">            
          <h4>${facilityMarker.name}</h4>
        </div>
      `;

      const infoWindow = new window.google.maps.InfoWindow({
        content
      });

      if (facilityMarker.visible) {
        infoWindow.open(map, marker);
      }

      marker.addListener('visible_changed', () => {
        if (marker.visible) {
          infoWindow.open(map, marker);
        } else {
          infoWindow.close(map, marker);
        }
      });

      marker.addListener("click", () => {
        this.handleFacilityOpen(marker);
      });
      
      editedFacilityMarkers.push(marker);
    });

    this.setState(prevState => ({
      facilityMarkers: {
        ...prevState.facilityMarkers,
        [tag.id]: editedFacilityMarkers
      }
    }));
  };

  requestLastLocationUpdate = () => {
    this.setState({
      requestingUpdateLastLocation: true,
    });
    // get selected user ids from their location pins
    const userSendIds = [];
    const userNotificationIds = [];

    const userNotificationNames = [];
    const groupNames = [];
    const { t } = this.props;

    this.state.lastLocationMarkers.forEach((marker) => {
      if(marker.visible) {
        userSendIds.push(marker.userId);
      };
    });

    userSendIds.forEach((id) => {
      var userRecord = this.state.users.find((user) => user.id === id);
      if(userRecord && userRecord.customData && userRecord.customData.token) {
        userNotificationIds.push(userRecord.customData.token);
        userNotificationNames.push(userRecord.name);
      }
    });

    // make axios call to send location request to multiple users
    var parentGroupId = this.props.parentGroup.id;
    var senderId = this.props.user.id;

    axios({
      method: 'POST',
      url: "https://p7ivjbxa54.execute-api.ap-northeast-1.amazonaws.com/default/telenetRequestDeviceLocation",
      headers: {
        "x-api-key": "eSrGh4NtV322vU7sp8kqg6fzav0LqOEr4VPzy06z",
      },
      data: {
        alertIds: userNotificationIds,
        companyGroupId: parentGroupId,
        senderId: senderId,
        sendingToUserNames: userNotificationNames,
        sendingToGroupNames: groupNames,
        sendingToIds: this.state.checkedAlertGroupIds,
        requestType: "update_location"
      }
    }).then(response => {
      if(response.data.success) {
        // notify of success
        this.waitForDeviceResponse = setTimeout(() => {
          this.setState({ 
            prevSelectedLastLocationMarkerIds: userSendIds,
            showRealtimeMarkers: true
          }, () => {
            if(!this.state.lastLocationMarkers || this.state.lastLocationMarkers.length === 0) {
              throw new Error("No last location markers found.");
            };
            this.state.lastLocationMarkers.forEach(marker => {
              marker.setVisible(false);
            });
            this.props.getGroups(true);
            this.waitToLoadPins = setTimeout(() => {
              this.setState({
                hasError: true, 
                errorMessage: t("Last location data has been retrieved."),
                errorType: "success",
                requestingUpdateLastLocation: false,
              }, () => {
                if(this.waitToLoadPins) {
                  clearTimeout(this.waitToLoadPins);
                }
              });
            },5000);
            if(this.waitForDeviceResponse) {
              clearTimeout(this.waitForDeviceResponse);
            }
          });
        }, 10000);
      } else {
        // request failed
        this.handleError(t("Failed to retrieve location information."));
        this.setState({
          requestingUpdateLastLocation: false,
          showRealtimeMarkers: false
        });
      }
    }).catch((error) => {
      console.log("throw", error);
      this.handleError(t("Failed to retrieve location information."));
      this.setState({
        requestingUpdateLastLocation: false,
        showRealtimeMarkers: false
      });
    });

  }

  toggleSendNotify = () => {
    this.setState(prevState => ({
      dialogNotifyOpen: !prevState.dialogNotifyOpen
    }));
  };

  handleCheckAllUserNotify = () => {
    const { groups, userCount } = this.props;
    const { checkedAlertUserIds } = this.state;

    handleCheckAllUserNotify({
      groups,
      checkedAlertUserIds,
      userCount,
      setState: this.setState.bind(this),
    });
  };

  handleCheckAllGroupNotify = () => {
    const ifAllSelected = this.state.checkedAlertGroupIds && this.props.groups && this.state.checkedAlertGroupIds.length === this.props.groups.length;
    if(ifAllSelected) {
      this.setState({
        checkedAlertGroupIds: []
      });
    } else {
      const gatherIds = this.props.groups.map((elm) => elm.id);
      this.setState({
        checkedAlertGroupIds: gatherIds
      });
    }
  }

  handleCheckGroupNotify = (id) => {
    const { checkedAlertGroupIds } = this.state;
    handleCheckGroupNotify({
      id,
      checkedAlertGroupIds,
      setState: this.setState.bind(this),
    });
  };

  handleCheckUserNotify = (id) => {
    const { checkedAlertUserIds } = this.state;
    handleCheckUserNotify({
      id,
      checkedAlertUserIds,
      setState: this.setState.bind(this),
    });
  };


  handleDeleteNotification = (selectedNotification) => {
    const { t, parentGroup, firebase } = this.props;
    const { notificationHistory } = this.state;

    handleDeleteNotification({
      selectedNotification,
      notificationHistory,
      setState: this.setState.bind(this),
      t,
      firebase,
      parentGroupId: parentGroup.id,
    });
  };

  handleDeleteNotificationOpen = selectedNotification => {
    const { deleteNotificationOpen } = this.state;

    if (deleteNotificationOpen) {
      this.setState({ deleteNotificationOpen: false });
    } else {
      this.setState({
        deleteNotificationOpen: true,
        selectedNotification
      });
    }
  };

  sendNotifications = async () => {
    const { t, groups, parentGroup, user } = this.props;
    const { checkedAlertGroupIds } = this.state;

    const notifyTitle = this.notifyTitle.current.value;
    const notifyMessage = this.notifyMessage.current.value;

    await sendNotifications({
      t,
      groups,
      checkedAlertGroupIds,
      parentGroupId: parentGroup.id,
      userId: user.id,
      notifyTitle,
      notifyMessage,
      setState: this.setState.bind(this),
      handleError: this.handleError.bind(this),
    });
  };


  sendNotificationsUser = async () => {
    const { t, groups, parentGroup, user } = this.props;
    const { checkedAlertUserIds } = this.state;

    const notifyTitle = this.notifyTitleUser.current.value;
    const notifyMessage = this.notifyMessageUser.current.value;

    await sendNotificationsUser({
      t,
      groups,
      checkedAlertUserIds,
      parentGroupId: parentGroup.id,
      userId: user.id,
      notifyTitle,
      notifyMessage,
      setState: this.setState.bind(this),
      handleError: this.handleError.bind(this),
      isDev: false, // Set to true for dev environment, false for prod
    });
  };

  handleChangeNotificationTab = (event, newValue) => {
    // need to clear the state of both user and group messages
    this.setState({
      valueNotificationTab: newValue,
      checkedAlertGroupIds: [],
      checkedAlertUserIds: [],
      sendingNotification: false,
    });

    if(this.notifyTitle && this.notifyTitle.current) {
      this.notifyTitle.current.value = "";
    }
    if(this.notifyMessage && this.notifyMessage.current) {
      this.notifyMessage.current.value = "";
    }
    if(this.notifyMessageUser && this.notifyMessageUser.current) {
      this.notifyMessageUser.current.value = "";
    }
    if(this.notifyTitleUser && this.notifyTitleUser.current) {
      this.notifyTitleUser.current.value = "";
    }

  };

  loadMoreButton = () => {
    const {t} = this.props;
    if (!this.state.loadingPlaces && !this.state.loadingMoreList && !this.state.loadingTabSwitch) {
      return (
        <Button
          color="primary"
          className={"load-more-btn"}
          onClick={
            this.state.currentTab === "public"
              ? () => this.loadMorePublicPlaces()
              : () => this.loadMorePlaces()
          }
        >
          {t("View More")}
        </Button>
      );
    } else {
      return (
        <Button
          color="primary"
          className={"load-more-btn disabled"}
          disabled={true}
        >
          <CircularProgress size={24} thickness={4} />
        </Button>
      );
    }
  };

  handleEditPlaceDeleteOpen = () => {
    this.setState(prevState => ({ editPlaceDeleteOpen: !prevState.editPlaceDeleteOpen }));
  };

  removeGroupPlaces = deletedGroupId => {
    // Filter out all places that belong to the deleted group
    const places = this.state.places;

    if (places && places.length > 0) {
      const filteredPlaces = [];
      const deletedPlaceIds = [];
  
      places.forEach(place => { 
        if (place.groupId === deletedGroupId) {
          deletedPlaceIds.push(place.placeId)
        } else {
          filteredPlaces.push(place);
        }
      });
  
      this.removePlaces(deletedPlaceIds);
    }
  };

  handleRefreshMapOnMapMove = (event) => {
    this.setState({
       [event.target.name]: event.target.checked
    })
  };

  updateMapOnMove = () => {
    if(this.state.refreshMapOnMapMove) {
      clearTimeout(this.searchAreaTimer);
      this.searchAreaTimer = setTimeout(() => {
        clearTimeout(this.searchAreaTimer);
        this.getPlacesByArea();
      }, 3000)
    }
  };

  toggleFilterDialog = () => {
    this.setState(prevState => ({
      openFilterDialog: !prevState.openFilterDialog
    }))
  };

  handleOpenFilterDialog = selectedTag => {
    const selectedFilterListObject = this.getFilterListObjs(selectedTag);

    this.setState({ 
      selectedTag,
      selectedFilterListObject,
      openFilterDialog: true 
    });
  };

  getFilterListObjs = selectedTag => {
    const {t} = this.props;
    switch (selectedTag) {
      case t("Devices"):
        return this.props.groups;
      case t("Facility Information"):
        return this.props.tags;
      case t("Admin Comment"):
        return this.state.comments;
      case t("Incident"):
        return this.props.incidentTags;
      default:
        return null;
    };
  };

  handleCloseFilterDialog = () => {
    this.setState({ 
      openFilterDialog: false 
    }, () => {
      this.setState({
        selectedTag: false,
        selectedFilterListObject: null,
      });
    });
  };

  handleOpenTogglePublic= () => {
    this.setState(prevState => ({ openTogglePublic: !prevState.openTogglePublic }));
  };

  handleOpenNotificationSound = () => {
    this.setState(prevState => ({ openNotificationSound: !prevState.openNotificationSound }));
  };

  handleToggleNotificationSound = () => {
    this.setState({ notificationSoundStatusLoading: true });

    const user = this.props.user;
    const notificationSound = this.state.notificationSound;

    const userUpdate = {
      customData: {
        notificationSound: notificationSound? "": "true"
      }
    };
    const { t } = this.props;

    geocore.users.update(user.id, userUpdate)
      .then(updatedUser => {
        this.props.updateUserInfo(updatedUser);
        this.setState({
          errorType: "success",
          hasError: true,
          errorMessage: `${t("The notification sound is")}${notificationSound? "OFF": "ON"}`,
          notificationSoundStatusLoading: false,
          notificationSound: !notificationSound,
          openTogglePublic: false
        });
      })
      .catch(err => {
        this.setState({
          errorType: "error",
          hasError: true,
          errorMessage: t("Could not update notification sound"),
          notificationSoundStatusLoading: false
        });
      });
  };

  handleTogglePublicTab = () => {
    
    this.setState({ publicTabToggleLoading: true });
    const { t } = this.props;

    const user = this.props.user;
    const publicTabVisible = this.state.publicTabVisible;

    const userUpdate = {
      customData: {
        publicTabVisible: publicTabVisible? "": "true"
      }
    };

    geocore.users.update(user.id, userUpdate)
      .then(updatedUser => {
        this.props.updateUserInfo(updatedUser);
        this.setState({
          errorType: "success",
          hasError: true,
          errorMessage: `${publicTabVisible? t("Could not update the Public tab."): t("The Public tab is now displayed.")}`,
          publicTabToggleLoading: false,
          publicTabVisible: !publicTabVisible,
          openTogglePublic: false
        });
      })
      .catch(err => {
        this.setState({
          errorType: "error",
          hasError: true,
          errorMessage: t("Public tab display cannot be updated"),
          publicTabToggleLoading: false
        });
      });
  };

  checkVisiblePublicTab = () => {
    const user = this.props.user;
    const publicTabVisible = user.customData && user.customData.publicTabVisible;

    if (publicTabVisible) {
      this.setState({
        publicTabVisible
      });
    }
  };


  hideAllPlaces = () => {
    //Todo hide all places
  };

  isRealtimeLocationStream = activeStreams => {
    let activeRealtimeLocationStreamCount = 0;

    activeStreams.forEach((activeStream, activeStreamsIndex) => {
      const realtimeProducerRef = firebase.database().ref(`realtime/${activeStream.producer}`);

      realtimeProducerRef.once("value", snapshot => {
        const realtimeData = snapshot.val();

        const roomIds = Object.keys(realtimeData);

        const hasRealtimeLocation = roomIds.some(roomId => roomId === activeStream.roomId);
  
        if (hasRealtimeLocation) {
          // This is a realtime location stream that has already been added to firebase
          activeRealtimeLocationStreamCount += 1;

          if (activeStreamsIndex === activeStreams.length - 1) {
            this.setState({ activeRealtimeLocationStreamCount });
          }
        }
      });
    });
  };

  checkNotificationSoundStatus = () => {
    const user = this.props.user;
    const notificationSound = user.customData && user.customData.notificationSound;

    if (notificationSound) {
      this.setState({
        notificationSound
      });
    }
  };

  stopUnmountLoading = () => {
    var ele = document.getElementById("livestream_detail_window");
    if(ele) {
      ele.click();
    }
    this.setState({unmountLoading: false});
  };


  render() {
    const isType = this.state.errorType? this.state.errorType : "error";
    const { user, isLiveApp } = this.props;
    const customData = user && user.customData? user.customData : null;
    const initialZoom = customData && customData.initialZoom? parseInt(customData.initialZoom) : 8;
    const initialLat = customData && customData.initialLat? parseFloat(customData.initialLat) : 35.6;
    const initialLng = customData && customData.initialLng? parseFloat(customData.initialLng) : 139.6;
    const mapStyle = customData && customData.mapStyle? customData.mapStyle : "simple";
    const visibleLastLocations = this.state.lastLocationMarkers && this.state.lastLocationMarkers.some(el => el.visible);
    const selectedNotifyDevices = this.state.checkedAlertGroupIds.length;
    const selectedNotifyUserDevices = this.state.checkedAlertUserIds.length;
    const { t } = this.props;

    return (
      <div className={this.state.os? `${this.state.os}_wrapper`: ''}>
        <PrintView 
          printingPlaceList={this.state.printingPlaceList} 
          printViewMode={this.state.printViewMode} 
          handleSelectedClose={this.handleSelectedClose} 
          loadingAction={this.state.loadingAction} 
        />

        {this.state.unmountLoading?
        <div className="unmounting-loader">
        <Button onClick={this.stopUnmountLoading} className="close-button live">
          <CloseIcon />
        </Button>
         <div className="loading-hc-stream">
            <CircularProgress
              size={50}
              thickness={6}
              className="loading-hc-selection"
            />
          </div>
        </div>:null}

        <Dialog
          open={this.state.openHazardCamStream? this.state.openHazardCamStream: false}
          onClose={this.handleCloseHazardCamStream}
          className={`group-select-notify-modal dark-mode-dialog hazardcam-dialog`}>
          {!this.state.loadingStream?
            <div className="loading-hc-stream">
              <CircularProgress
                size={50}
                thickness={6}
                className="loading-hc-selection"
              />
            </div>
          :null}
          <div className="hc-title-bar">
            {this.state.hazardCamRoomInfo?
              <>
              <div className="is-live-tag-list">LIVE</div>
              <p className="hc-user-name"><PersonIcon />{this.state.hazardCamRoomInfo.username}</p>
              <p className="hc-group-name">{this.state.hazardCamRoomInfo.roomInfo.roomInfo.groupName}</p>
              

              <div className="hazard-level-label list-label-hazard">
              <span className={`hazard-level-${hasLevelNumber(this.state.hazardCamRoomInfo.roomInfo.roomInfo.hazardLevel)}`}>
                {this.state.hazardCamRoomInfo.roomInfo.roomInfo.hazardLevel}
              </span>
            </div>


              </>:null
            }
            <Button className={"close-notification-dialog close-hc-stream"} onClick={this.handleCloseHazardCamStream}>
                <CloseIcon/>
            </Button>
          </div>
          <video ref={(ref) => this.setupHCStream(ref)} autoPlay className="hazardCameraVideo"></video>
          <div style={{ backgroundImage: "url('/telenet-connecting.png')"}} className="loading-hc-stream"></div>
        </Dialog>
      


          <Dialog
            open={this.state.dialogNotifyOpen? this.state.dialogNotifyOpen: false}
            onClose={this.toggleSendNotify}
            className={`group-select-notify-modal ${this.props.isLiveApp? "dark-mode-dialog": ""}`}>
            {!this.state.sendingNotification?
              <Button className={"close-notification-dialog"} onClick={this.toggleSendNotify}>
                <CloseIcon/>
              </Button>:null
            }
            <div className="notify-group-form-messages">
                {this.state.sendingNotification?
                  <div className="sending-notification">
                    <div className="spinner-center">
                    <CircularProgress
                      size={30}
                      thickness={6}
                      className="sending-notification-loading"/>
                    </div>
                  </div>:null
                }
              <div className="form-wrapper-notifications">
                <div className="tabs-wrap-notifications">
                <Tabs
                  value={this.state.valueNotificationTab}
                  onChange={this.handleChangeNotificationTab}
                  aria-label="notification-tabs"
                  TabIndicatorProps={{
                    style: { 
                      background: '#0e6aad'
                    }
                  }}
                >
                  <Tab icon={  <div className="history-type tab-type">
                    <p>{t("New")}</p>
                      <div className="history-group-type"><span>{t("Group")}</span></div>
                    </div>}>
                  
                  </Tab>
                  <Tab icon={ <div className="history-type tab-type">
                  <p>{t("New")}</p>
                      <div className="history-user-type"><span>{t("User")}</span></div>
                    </div>}>
                   
                  </Tab>
                  <Tab label={t("History")}/>
                </Tabs>
                </div>
                <div className={`tab-notification-panel-wrap ${this.state.valueNotificationTab === 2? "full-panel-size": ''}`}>

                <TabPanel value={this.state.valueNotificationTab} index={0}>
                  <Typography variant="h2">{t("Create a Group Message")}</Typography>
                 <FormControl margin="normal" fullWidth>
                    <TextField
                      name={"NotificationTitle"}
                      label={t("Title")}
                      autoComplete="off"
                      variant="outlined"
                      placeholder={t("Title")}
                      className="dark-mode-outline-input"
                      required={true}
                      inputRef={this.notifyTitle}
                    />
                  </FormControl>  

                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name={"NotificationMessage"}
                      label={t("Message")}
                      autoComplete="off"
                      variant="outlined"
                      placeholder={t("Message")}
                      className="dark-mode-outline-textarea"
                      required={true}
                      multiline={true}
                      rows={10}
                      rowsMax={10}
                      inputRef={this.notifyMessage}
                    />
                  </FormControl>

                  <Button
                    onClick={this.sendNotifications}
                    disabled={selectedNotifyDevices === 0}
                    className={`sending-notification-btn ${selectedNotifyDevices === 0? "disabled-send-btn": ""}`}
                    >
                    <SendIcon />
                    {t("Send Message")}
                  </Button>
                </TabPanel>
                <TabPanel value={this.state.valueNotificationTab} index={1}>
                  <Typography variant="h2">{t("Create a User Message")}</Typography>
                 <FormControl margin="normal" fullWidth>
                    <TextField
                      name={"NotificationTitleUser"}
                      label={t("Title")}
                      autoComplete="off"
                      variant="outlined"
                      placeholder={t("Title")}
                      className="dark-mode-outline-input"
                      required={true}
                      inputRef={this.notifyTitleUser}
                    />
                  </FormControl>  

                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name={"NotificationMessageUser"}
                      label={t("Message")}
                      autoComplete="off"
                      variant="outlined"
                      placeholder={t("Message")}
                      className="dark-mode-outline-textarea"
                      required={true}
                      multiline={true}
                      rows={10}
                      rowsMax={10}
                      inputRef={this.notifyMessageUser}
                    />
                  </FormControl>

                  <Button
                    onClick={this.sendNotificationsUser}
                    disabled={selectedNotifyUserDevices === 0}
                    className={`sending-notification-btn ${selectedNotifyUserDevices === 0? "disabled-send-btn": ""}`}
                    >
                    <SendIcon />
                    {t("Send Message")}
                  </Button>
                </TabPanel>
                <TabPanel value={this.state.valueNotificationTab} index={2}>
                  {this.state.notificationHistory && this.state.notificationHistory.map((history, index) => (
                    <div key={`history-${index}`}>
                    {history.type === 'notify_group' || history.type === 'notify_user' ?
                      <div className="history-record" key={`history-item-${index}`}>
                      <div className="history-type">
                        {history.type === 'notify_user'?
                        <div className="history-user-type">{t("User")}</div>
                        :<div className="history-group-type">{t("Group")}</div>}
                      </div>
                      <div className={`notification-type ${history.type}`}>
                        <Typography>{t("Notices")}</Typography>
                      </div>
                      <div className="history-delete-btn" onClick={() => this.handleDeleteNotificationOpen(history)}>X</div>
                      <Linkify>
                      <Typography className='msg-title'><span>{t("Title")}</span>{history.msg_title}</Typography>
                      <Typography className='msg-content'><span>{t("Message")}</span>{history.msg_content}</Typography>
                      </Linkify>
                      <div className="sent-to-groups">
                        <span>{t("Group Name")}</span><Typography>{history.send_to_group_names? history.send_to_group_names.join(', '): ''}</Typography>
                      </div>
                      <div className="sent-time">
                        <span>{t("Sent Date")}</span>{ moment(history.sent_time).format("YYYY/MM/DD HH:mm")}
                      </div>
                      </div>:null
                    }
                    </div>
                  ))}

                  {!this.state.notificationHistory?
                    <div className="no-history">
                      <Typography>{t("No messages have been sent")}</Typography>
                    </div>:null
                  }

                </TabPanel>
                <p style={{fontSize: 11, fontFamily: "Roboto, Helvetica,Arial, sans-serif", color: "#646464", position: "absolute", bottom: 35, left: 20 }}>ハザードビュー v4.9.11以前のバージョンでご利用になれます</p>
                </div>
                </div>

            </div>

            <div className="notify-group-form">

              {this.state.valueNotificationTab === 0?
               <>
              <FormLabel component="legend">
                {selectedNotifyDevices > 0?
                  <React.Fragment>{`${selectedNotifyDevices} ${t("Groups Selected")}`}</React.Fragment>:
                  <React.Fragment>{t("Selected Groups")}</React.Fragment>
                }
                </FormLabel>
              <FormControl component="fieldset">
                
                <FormGroup aria-label="position" column="true">
                  <FormControlLabel
                    value={t("View All")}
                    key={`groupagKey-selectall-1`}
                    control={
                      <CustomCheckbox
                        onClick={() => this.handleCheckAllGroupNotify()}
                        checked={this.state.checkedAlertGroupIds && this.props.groups && this.state.checkedAlertGroupIds.length === this.props.groups.length}
                      />
                    }
                    label={t("View All")}
                    labelPlacement="end"
                  />
                  {this.props.groups && this.props.groups.map((group, index) => (
                    <FormControlLabel
                      value={`${group.name}`}
                      key={`groupagKey-${group.name}-${index}`}
                      control={
                        <CustomCheckbox
                          onClick={() => this.handleCheckGroupNotify(group.id)}
                          checked={this.state.checkedAlertGroupIds && this.state.checkedAlertGroupIds.some((id) => id === group.id)}
                        />
                      }
                      label={group.name}
                      labelPlacement="end"
                    />
                  ))}
                </FormGroup>
              </FormControl>
              </>
              :null}

              {this.state.valueNotificationTab === 1?<>
              <FormLabel component="legend">
                {selectedNotifyUserDevices > 0?
                  <React.Fragment>{`${selectedNotifyUserDevices} ${t("Users Selected")}`}</React.Fragment>:
                  <React.Fragment>{t("Selected Users")}</React.Fragment>
                }
                </FormLabel>
              <FormControl component="fieldset">
                
                <FormGroup aria-label="position" column="true">
                  <FormControlLabel
                    value={t("View All")}
                    key={`groupagKey-selectall-1`}
                    control={
                      <CustomCheckbox
                        onClick={() => this.handleCheckAllUserNotify()}
                        checked={this.state.checkedAlertUserIds && this.props.groups && this.state.checkedAlertUserIds.length === this.props.userCount}
                      />
                    }
                    label={t("View All")}
                    labelPlacement="end"
                  />
                  {this.props.groups && this.props.groups.map((group, index) => (
                    <div key={`user-list-group-id-${group.id}`}>
                    <div className="group-users-name-block">
                    <span className="group-name-list-title">{group.name}</span>
                    {!group.users || group.users.length < 1?
                      <span className="no-users-in-group-for-msg">{t("No users in this group")}</span>:null
                    }
                    {group.users.map((user) => (
                      <FormControlLabel
                      value={`${user.id}`}
                      key={`user-msg-${user.id}`}
                      control={
                        <CustomCheckbox
                          onClick={() => this.handleCheckUserNotify(user.id)}
                          checked={this.state.checkedAlertUserIds && this.state.checkedAlertUserIds.some((id) => id === user.id)}
                        />
                      }
                      label={user.name}
                      labelPlacement="end"
                      />
                    ))}
                    </div>
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
              </>:null
            }



            </div>

          </Dialog>

        <AlertSnackbar
          isType={isType}
          isOpen={this.state.hasError}
          handleClick={this.handleErrorAlertClick}
          message={this.state.errorMessage}
        />

        {this.state.activeButton === 2 && isLiveApp?
          <>
            <MonitorModeScreen
              user={this.props.user}
              places={this.state.places}
              dragEnd={this.state.dragEnd}
              handleClearDrag={this.handleClearDrag}
              draggedPlace={this.state.draggedPlace}
              parentGroup={this.props.parentGroup}
              openSidebar={this.state.openSidebar}
              handleOpenSidebar={this.handleOpenSidebar}
              handlePropOpen={this.handlePropOpen}
              leaveRoom={this.leaveRoom}
              joinRoom={this.joinRoom}
              joinRoomHazardCam={this.joinRoomHazardCam}
              leaveRoomHazardCam={this.leaveRoomHazardCam}
              socket={this.hazardLiveSocket}
              hcSocket={this.hazardCamSocket? this.hazardCamSocket: null}
              handleScreenBuilderOpen={this.handleScreenBuilderOpen}
              openViduStreamPlaces={this.state.openViduStreamPlaces}
            />
            <HazardLiveRealtimeMapBuilder 
              user={this.props.user}
              openRealtimeMapSidebar={this.state.openRealtimeMapSidebar}
              handleToggleRealtimeMapSidebar={this.handleToggleRealtimeMapSidebar}
              handleOpenRealtimeMapSidebar={this.handleOpenRealtimeMapSidebar}
              handleRealtimeMapBuilderOpen={this.handleRealtimeMapBuilderOpen}
              screenBuilderOpen={this.state.screenBuilderOpen}
              webRTCLiveList={this.state.webRTCLiveList}
              hoveredLivePlace={this.state.hoveredLivePlace}
              activeRealtimeLocationStreamCount={this.state.activeRealtimeLocationStreamCount}
            />
          </>
          :
          null
        }
        {isLiveApp?
          <HazardCamDialog open={this.state.hazardCamOpen} camera={this.state.hazardCamType} closeHazardCamStream={this.closeHazardCamStream}/>
          : null 
        }
        <div className="filter-list-column">
        <div className="filter-list-overflow">
          <NavigationBar 
            handleUserLogout={this.props.handleUserLogout} 
            handleUserSettings={this.handleUserSettings}
          />
          <UserInfoBar
            parentGroup={this.props.parentGroup}
            user={this.props.user}
          />
          {this.state.loadingPlaces ||
            this.state.loadingMoreList ||
            this.state.loadingPlacesByArea ||
            this.state.loadingPublicPlace ||
            this.state.loadingHazardName ||
            this.state.loadingDelete ||
            this.state.requestingUpdateLastLocation?
            <div className="disable-filters-list">
              <CircularProgress
                size={20}
                thickness={6}
                className="loading-filter-selection"
              />
            </div>:null}

            <Filters
              isLiveApp={isLiveApp}
              user={this.props.user}
              levels={this.props.levels}
              groups={this.props.groups}
              comments={this.state.comments}
              parentGroup={this.props.parentGroup}
              hideAllPlaces={this.hideAllPlaces}
              tags={this.props.tags}
              incidentTags={this.props.incidentTags}
              registerFilterItems={this.registerFilterItems}
              setDates={this.setDates}
              filterByTaisaku={this.state.filterByTaisaku}
              toggleTaisakuFilters={this.toggleTaisakuFilters}
              activeButton={this.state.activeButton}
              loadingPlaces={this.state.loadingPlaces}
              removePlaceIncident={this.removePlaceIncident}
              removeIncidentTag={this.props.removeIncidentTag}
              updateIncidentPlaces={this.updateIncidentPlaces}
              geocoreAddIncidentTag={this.geocoreAddIncidentTag}
              filterRange={this.state.filterRange}
              filterByLists={this.state.filterByLists}
              storageLimit={this.props.storageLimit}
            />
        </div>
        </div>
        <div className={this.state.openSidebar? "hazard-map-column": "hazard-map-column closed"}>
          {isLiveApp?
            <>
            <LiveStreamSingle
              open={this.state.openLiveStream}
              user={this.props.user}
              parentGroup={this.props.parentGroup}
              handleClose={this.handleClose}
              place={this.state.selectedLivePlace}
              handleEditPlaceOpen={this.handleEditPlaceOpen}
              isWebRTC={this.state.isWebRTC}
              webRTCRoomId={this.state.webRTCRoomId}
              leaveRoom={this.leaveRoom}
              roomInfo={this.state.roomInfo}
              socket={this.hazardLiveSocket}
              createPlace={this.handleCreatePlace}
              handleVideoSnapshot={this.handleVideoSnapshot}
              createPlaceLoading={this.state.createPlaceLoading}
              handleMarkAsDeleted={this.handleMarkAsDeleted}
              handleSnapshotOpen={this.handleSnapshotOpen}
              handleSnapshotClose={this.handleSnapshotClose}
              snapshotOpen={this.state.snapshotOpen}
              openViduStreamPlaces={this.state.openViduStreamPlaces}
            />
            <ModalLiveStreamDetails
              open={this.state.openLiveStorage}
              user={this.props.user}
              parentGroup={this.props.parentGroup}
              handleClose={this.handleClose}
              place={this.state.selectedPlace}
              handleEditPlaceOpen={this.handleEditPlaceOpen}
              isWebRTC={this.state.isWebRTC}
              webRTCRoomId={this.state.webRTCRoomId}
              leaveRoom={this.leaveRoom}
              roomInfo={this.state.roomInfo}
              socket={this.hazardLiveSocket}
              createPlace={this.handleCreatePlace}
              handleVideoSnapshot={this.handleVideoSnapshot}
              createPlaceLoading={this.state.createPlaceLoading}
              handleSnapshotOpen={this.handleSnapshotOpen}
              handleSnapshotClose={this.handleSnapshotClose}
              snapshotOpen={this.state.snapshotOpen}
              openViduStreamPlaces={this.state.openViduStreamPlaces}
            />

            </>
            : null
          }


          <ModalPlaceDetails
            open={this.state.open}
            handleClose={this.handleClose}
            place={this.state.selectedPlace}
            handleEditPlaceOpen={this.handleEditPlaceOpen}
            handleDownloadPlace={this.handleDownloadPlace}
            filterByTaisaku={this.state.filterByTaisaku}
            user={this.props.user}
            groups={this.props.groups && this.props.groups.map((g) => g.id)}
          />
          <ModalLastLocationDetails
            open={this.state.openLastLocation}
            handleClose={this.handleClose}
            lastLocation={this.state.selectedLastLocation}
          />
          <ModalFacilityDetails 
            open={this.state.openFacility}
            handleClose={this.handleClose}
            facility={this.state.selectedFacility}
          />
          <ModalCommentDetails 
            open={this.state.openComment}
            handleClose={this.handleClose}
            comment={this.state.selectedComment}
          />
          <EditPlaceListDialog 
            open={this.state.editPlaceOpen}
            handleClose={this.handleEditPlaceClose}
            selectedPlace={this.state.selectedPlace}
            updatePlace={this.updatePlace}
            loadingDelete={this.state.loadingDelete}
            updatePhoto={this.updatePhoto}
            handlePublicOpen={this.handlePublicOpen}
            isPublicOpen={this.state.isPublicOpen}
            handlePublicPlace={this.handlePublicPlace}
            loadingPublicPlace={this.state.loadingPublicPlace}
            handleError={this.handleError}
            handleMarkAsDeleted={this.handleMarkAsDeleted}
            handleEditPlaceDeleteOpen={this.handleEditPlaceDeleteOpen}
            editPlaceDeleteOpen={this.state.editPlaceDeleteOpen}
            user={this.props.user}
            levels={this.props.levels}
            parentGroup={this.props.parentGroup}
            incidentTags={this.props.incidentTags}
          />
          <DefaultSettingsDialog 
            open={this.state.openSettings}
            close={this.handleOpenSettings}
            initialZoom={initialZoom}
            initialLat={initialLat}
            initialLng={initialLng}
            updateSettings={this.updateSettings}
            loadingAction={this.state.loadingAction}
          />
          <HazardUserSettings 
            open={this.state.openUserSettings}
            handleClose={this.handleUserSettings} 
            parentGroup={this.props.parentGroup}
            levels={this.props.levels}
            updateHazardLevelName={this.updateHazardLevelName}   
            loading={this.state.loadingHazardName}   
            handleOpenFilterDialog={this.handleOpenFilterDialog}   
            handleOpenTogglePublic={this.handleOpenTogglePublic}   
            handleOpenNotificationSound={this.handleOpenNotificationSound}   
          />
          <FilterDialog
            open={this.state.openFilterDialog}
            handleOpen={this.handleOpenFilterDialog}
            handleClose={this.handleCloseFilterDialog}
            user={this.props.user}
            tag={this.state.selectedTag}
            filterListObjs={this.state.selectedFilterListObject}
            parentGroup={this.props.parentGroup}
            getGroups={this.props.getGroups}
            getTags={this.props.getTags}
            getComments={this.props.getComments}
            addComment={this.addComment}
            removeCommentMarker={this.removeCommentMarker}
            editComment={this.editComment}
            createNewFacilityMarker={this.createNewFacilityMarker}
            removeFacilityMarker={this.removeFacilityMarker}
            updateFacilityGroup={this.props.updateFacilityGroup}
            updateFacilityMarkers={this.updateFacilityMarkers}
            removePlaceIncident={this.removePlaceIncident}
            removeIncidentTag={this.props.removeIncidentTag}
            removeGroup={this.props.removeGroup}
            removeFacilityGroup={this.props.removeFacilityGroup}
            removeFacilityMarkers={this.removeFacilityMarkers}
            removeGroupPlaces={this.removeGroupPlaces}
            updateIncidentPlaces={this.updateIncidentPlaces}
            geocoreAddIncidentTag={this.geocoreAddIncidentTag}
            editFacility={this.editFacility}
            isLiveApp={isLiveApp}
            handleOpenFilterDialog={this.handleOpenFilterDialog}
          />
          <DeleteNotificationDialog 
            open={this.state.deleteNotificationOpen}
            close={this.handleDeleteNotificationOpen}
            handleDeleteNotification={this.handleDeleteNotification}
            selectedNotification={this.state.selectedNotification}
            loadingDelete={this.state.loadingDelete}
          />
          <TogglePublicTabDialog 
            open={this.state.openTogglePublic}
            close={this.handleOpenTogglePublic}
            publicTabVisible={this.state.publicTabVisible}
            loading={this.state.publicTabToggleLoading}
            handleTogglePublicTab={this.handleTogglePublicTab}
          />
          <ToggleNotificationSoundDialog 
            open={this.state.openNotificationSound}
            close={this.handleOpenNotificationSound}
            notificationSound={this.state.notificationSound}
            loading={this.state.notificationSoundStatusLoading}
            handleToggleNotificationSound={this.handleToggleNotificationSound}
          />
          {this.state.loadingPlaces? 
            <div className="loading-map">
              <div className="loading-map-spinner-container">
                <CircularProgress
                  size={40}
                  thickness={6}
                  className="loading-map-spinner"
                />
              </div>
            </div>
          : null}

          <div className="map-styles-container">
            <div className="map-button simple" onClick={() => this.handleMapStyle("simple")} style={{"order": "1"}}></div>
            <div className="map-button satellite" onClick={() => this.handleMapStyle("satellite")} style={{"order": "2"}}></div>
            <div className="map-button terrain" onClick={() => this.handleMapStyle("terrain")} style={{"order": "3"}}></div>
            <div className="map-button hazard" onClick={() => this.handleMapStyle("hazard")} style={{"order": "4"}}></div>
          </div>

          <Button
              className={`push-notify-lastlocation-devices custom-message ${visibleLastLocations? 'offset-left-center': ''} ${this.state.requestingUpdateLastLocation? 'hide-button':''}`}
              onClick={this.toggleSendNotify}
              >
              <SendIcon/>
            </Button>


          <Button
            className={`push-notify-lastlocation-devices ${visibleLastLocations? 'display-extra-btn': ''} ${this.state.requestingUpdateLastLocation? 'loading-request': ''}`}
            disabled={this.state.requestingUpdateLastLocation || !visibleLastLocations? true: false}
            onClick={this.requestLastLocationUpdate}
            >
            {this.state.requestingUpdateLastLocation?
              <CircularProgress
                size={30}
                thickness={6}
                className="loading-location-request-spinner"
              />:
              <React.Fragment><LastLocationIcon/> {t("Realtime")}</React.Fragment>
            }
          </Button>
          {this.state.requestingUpdateLastLocation?
            <React.Fragment>
              <div className="loading-last-location-wrap">
                <div className="tooltip-loading-last-locations">
                  <Typography>{t("Acquiring updated location data from device")}</Typography>
                </div>
              </div>
            </React.Fragment>:null
          }


          {isLiveAppMode?
            null:
            <div className="search-while-map-moves">
            <FormControlLabel
              control={<Checkbox checked={this.state.refreshMapOnMapMove} onChange={this.handleRefreshMapOnMapMove} name="refreshMapOnMapMove" />}
              label={t("Search when map moves")}
            />
          </div>
          }

          <HazardMap
            id="HazardMap"
            options={{
              center: { lat: initialLat, lng: initialLng },
              zoom: initialZoom,
              minZoom: 4,
              maxZoom: 30,
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false
            }}
            onMapLoad={map => {
              this.setState({ map }, () => {
                this.state.map.addListener("dragend", () => {
                  this.updateMapOnMove();
                });
              });
            }}
            userSettings={this.props.user && this.props.user.customData? this.props.user.customData : null}
            getPlacesByArea={this.getPlacesByArea}
            canQueryMore={this.state.canQueryMore}
            handleOpenSettings={this.handleOpenSettings}
            restoreDefaults={this.restoreDefaults}
            mapStyle={mapStyle}
          />
        </div>
        <div className={this.state.isDragging? "places-list-column dragging-to-builder": this.state.openSidebar? "places-list-column": "places-list-column closed"}>
        <div className="place-list-overflow-wrap">
          {this.state.loadingPlaces || this.state.loadingPlacesByArea || this.state.requestingUpdateLastLocation? (
            <div className="loading-place-list">
              <CircularProgress
                size={20}
                thickness={6}
                className="loading-place-spinner"
              />
            </div>
          ) : (
            <React.Fragment>
              <div className={this.state.videoListShow && (this.state.screenBuilderOpen || this.state.realtimeMapBuilderOpen)? "show-video-list dark-mode": (this.state.screenBuilderOpen || this.state.realtimeMapBuilderOpen)? "show-video-list dark-mode":  this.state.videoListShow? "show-video-list": "hide-video-list"}>
                <LiveStreamList
                  places={this.state.places}
                  placeAmount={this.state.placeAmount}
                  handleDragStop={this.handleDragStop}
                  handleDragStart={this.handleDragStart}
                  isScreenBuilderOpen={this.state.screenBuilderOpen}
                  handleLiveStreamListClick={this.handleLiveStreamListClick}
                  loadMorePlaces={this.loadMorePlaces}
                  loading={this.state.loadingPlaces}
                  handleIsWebRTC={this.handleIsWebRTC}
                  handleIsHazardCam={this.handleIsHazardCam}
                  loadingMoreList={this.state.loadingMoreList}
                  webRTCLiveList={this.state.webRTCLiveList}
                  hazardCamLiveStreamList={this.state.hazardCamLiveStreamList}
                  loadMoreButton={this.loadMoreButton}
                  canQueryMore={this.state.canQueryMore}
                  loadingTabSwitch={this.state.loadingTabSwitch}
                  getPlacesByArea={this.getPlacesByArea}
                  handleCheckAll={this.handleCheckAll}
                  handleSelectedOpen={this.handleSelectedOpen}
                  handleSelectedClose={this.handleSelectedClose}
                  allChecked={this.state.allChecked}
                  handleCheckbox={this.handleCheckbox}
                  checkboxData={this.state.checkboxData}
                  incidentTagFormOpen={this.state.incidentTagFormOpen}
                  handleCreateIncidentTag={this.handleCreateIncidentTag}
                  loadingAction={this.state.loadingAction}
                  incidentTags={this.props.incidentTags}
                  selectedAction={this.state.selectedAction}
                  selectedOpen={this.state.selectedOpen}
                  realtimeMapBuilderOpen={this.state.realtimeMapBuilderOpen}
                  handleLiveListHover={this.handleLiveListHover}
                  openViduStreamPlaces={this.state.openViduStreamPlaces}
                />
              </div>
              <div className={this.state.videoListShow || this.state.screenBuilderOpen? "hide-photo-list": "show-photo-list"}>
                {isLiveApp? <div></div>: 
                  <PlacesList
                    places={this.state.places}
                    placeAmount={this.state.placeAmount}
                    taisakuAmount={this.state.taisakuAmount}
                    disabledTaisaku={this.state.disabledTaisaku}
                    handlePlaceListClick={this.handlePlaceListClick}
                    handlePlaceListHover={this.handlePlaceListHover}
                    filterByTaisaku={this.state.filterByTaisaku}
                    loadMorePlaces={this.loadMorePlaces}
                    canQueryMore={this.state.canQueryMore}
                    loadMorePublicPlaces={this.loadMorePublicPlaces}
                    loading={this.state.loadingPlaces}
                    loadingMoreList={this.state.loadingMoreList}
                    placeListTabSwitch={this.placeListTabSwitch}
                    loadingTabSwitch={this.state.loadingTabSwitch}
                    currentTab={this.state.currentTab}
                    handleCheckbox={this.handleCheckbox}
                    checkboxData={this.state.checkboxData}
                    amountSelected={this.state.amountSelected}
                    selectedOpen={this.state.selectedOpen}
                    incidentTagFormOpen={this.state.incidentTagFormOpen}
                    handleSelectedOpen={this.handleSelectedOpen}
                    handleSelectedClose={this.handleSelectedClose}
                    handleCheckAll={this.handleCheckAll}
                    handleMakeTaisaku={this.handleMakeTaisaku}
                    handleMarkSelectedAsDeleted={this.handleMarkSelectedAsDeleted}
                    handlePrintSelected={this.handlePrintSelected}
                    handleExportSelected={this.handleExportSelected}
                    selectedAction={this.state.selectedAction}
                    actionProgress={this.state.actionProgress}
                    loadingAction={this.state.loadingAction}
                    alertNewPlace={this.state.alertNewPlace}
                    alertCounter={this.state.alertCounter}
                    hideAlert={this.hideAlert}
                    getPlacesByArea={this.getPlacesByArea}
                    getRecentPlaces={this.getNotificationNewPlaces}
                    handlePrintViewMode={this.handlePrintViewMode}
                    handleCreateIncidentTag={this.handleCreateIncidentTag}
                    incidentTags={this.props.incidentTags}
                    hasTaisaku={this.state.filterByTaisaku}
                    filterByLists={this.state.filterByLists}
                    allChecked={this.state.allChecked}
                    loadMoreButton={this.loadMoreButton}
                    getImages={this.getImages}
                    publicListEmptyAlert={this.state.publicListEmptyAlert}
                    publicTabVisible={this.state.publicTabVisible}
                  />                
                }
              </div>
            </React.Fragment>
          )}
        </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Dashboard)