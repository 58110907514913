import axios from "axios";

const sendNotifications = async ({
  t,
  groups,
  checkedAlertGroupIds,
  parentGroupId,
  userId,
  notifyTitle,
  notifyMessage,
  setState,
  handleError,
}) => {
  setState({ sendingNotification: true });

  const userNotificationIds = [];
  const userNotificationNames = [];
  const groupNames = [];

  const groupList = groups.filter((group) => checkedAlertGroupIds.includes(group.id));

  groupList.forEach((group) => {
    groupNames.push(group.name);
    group.users &&
      group.users.forEach((userRecord) => {
        if (userRecord?.customData?.token) {
          userNotificationIds.push(userRecord.customData.token);
          userNotificationNames.push(userRecord.name);
        }
      });
  });

  try {
    const response = await axios({
      method: "POST",
      url: "https://p7ivjbxa54.execute-api.ap-northeast-1.amazonaws.com/default/telenetRequestDeviceLocation",
      headers: {
        "x-api-key": "eSrGh4NtV322vU7sp8kqg6fzav0LqOEr4VPzy06z",
      },
      data: {
        alertIds: userNotificationIds,
        titleMessage: notifyTitle,
        contentMessage: notifyMessage,
        sendingToIds: checkedAlertGroupIds,
        sendingToUserNames: userNotificationNames,
        sendingToGroupNames: groupNames,
        companyGroupId: parentGroupId,
        senderId: userId,
        requestType: "notify_group",
      },
    });

    if (response.data.success) {
      setState({
        hasError: true,
        errorMessage: t("Message sent to group"),
        errorType: "success",
        sendingNotification: false,
        dialogNotifyOpen: false,
        checkedAlertGroupIds: [],
      });
    } else {
      handleError(t("Could not send message to target group."));
      setState({ sendingNotification: false });
    }
  } catch (error) {
    console.error(error);
    handleError(t("Could not send message to target group."));
    setState({ sendingNotification: false });
  }
};

const sendNotificationsUser = async ({
  t,
  groups,
  checkedAlertUserIds,
  parentGroupId,
  userId,
  notifyTitle,
  notifyMessage,
  setState,
  handleError,
  isDev = false, // Flag to toggle between dev and prod URLs
}) => {
  setState({
    sendingNotification: true,
  });

  const userNotificationIds = [];
  const userNotificationNames = [];
  const groupNames = [];

  // Filter and process users
  groups.forEach((group) => {
    const users = group.users?.filter((user) => checkedAlertUserIds.includes(user.id)) || [];
    users.forEach((userRecord) => {
      if (userRecord?.customData?.token) {
        groupNames.push(group.name);
        userNotificationIds.push(userRecord.customData.token);
        userNotificationNames.push(userRecord.name);
      }
    });
  });

  // Determine the URL and API key
  const notificationUrl = isDev
    ? "https://42elyglgw5.execute-api.ap-northeast-1.amazonaws.com/default/DEV_telenetRequestDeviceLocation"
    : "https://p7ivjbxa54.execute-api.ap-northeast-1.amazonaws.com/default/telenetRequestDeviceLocation";
  const notificationKey = isDev
    ? "hY7yzlOS9Ki7eYwiEnv88FQ1tDuxQLS1mi2cPy1i"
    : "eSrGh4NtV322vU7sp8kqg6fzav0LqOEr4VPzy06z";

  try {
    const response = await axios({
      method: "POST",
      url: notificationUrl,
      headers: {
        "x-api-key": notificationKey,
      },
      data: {
        alertIds: userNotificationIds,
        titleMessage: notifyTitle,
        contentMessage: notifyMessage,
        sendingToIds: checkedAlertUserIds,
        sendingToUserNames: userNotificationNames,
        sendingToGroupNames: groupNames,
        companyGroupId: parentGroupId,
        senderId: userId,
        requestType: "notify_user",
      },
    });

    if (response.data.success) {
      setState({
        hasError: true,
        errorMessage: "Message sent to user",
        errorType: "success",
        sendingNotification: false,
        dialogNotifyOpen: false,
        checkedAlertUserIds: [],
      });
    } else {
      handleError(t("Message could not be sent to the target user."));
      setState({ sendingNotification: false });
    }
  } catch (error) {
    console.error(error);
    handleError(t("Message could not be sent to the target user."));
    setState({ sendingNotification: false });
  }
};

const handleCheckAllUserNotify = ({ groups, checkedAlertUserIds, userCount, setState }) => {
  const ifAllSelected = checkedAlertUserIds && groups && checkedAlertUserIds.length === userCount;

  if (ifAllSelected) {
    // If all users are selected, deselect them
    setState({
      checkedAlertUserIds: [],
    });
  } else {
    // Otherwise, select all user IDs
    const allUserIds = [];
    groups.forEach((group) => {
      group.users.forEach((user) => {
        allUserIds.push(user.id);
      });
    });
    setState({
      checkedAlertUserIds: allUserIds,
    });
  }
};

const handleCheckGroupNotify = ({ id, checkedAlertGroupIds, setState }) => {
  const getIdPos = checkedAlertGroupIds.findIndex((elm) => elm === id);

  if (getIdPos === -1) {
    // Add the ID to the list
    setState((prevState) => ({
      checkedAlertGroupIds: prevState.checkedAlertGroupIds.concat(id),
    }));
  } else if (getIdPos === 0) {
    // Remove the first ID
    setState((prevState) => ({
      checkedAlertGroupIds: prevState.checkedAlertGroupIds.slice(1),
    }));
  } else if (getIdPos === checkedAlertGroupIds.length - 1) {
    // Remove the last ID
    setState((prevState) => ({
      checkedAlertGroupIds: prevState.checkedAlertGroupIds.slice(0, -1),
    }));
  } else if (getIdPos > 0) {
    // Remove an ID in the middle of the array
    setState((prevState) => ({
      checkedAlertGroupIds: [
        ...prevState.checkedAlertGroupIds.slice(0, getIdPos),
        ...prevState.checkedAlertGroupIds.slice(getIdPos + 1),
      ],
    }));
  }
};

const handleCheckUserNotify = ({ id, checkedAlertUserIds, setState }) => {
  const getIdPos = checkedAlertUserIds.findIndex((elm) => elm === id);

  if (getIdPos === -1) {
    // Add the ID to the list
    setState((prevState) => ({
      checkedAlertUserIds: prevState.checkedAlertUserIds.concat(id),
    }));
  } else if (getIdPos === 0) {
    // Remove the first ID
    setState((prevState) => ({
      checkedAlertUserIds: prevState.checkedAlertUserIds.slice(1),
    }));
  } else if (getIdPos === checkedAlertUserIds.length - 1) {
    // Remove the last ID
    setState((prevState) => ({
      checkedAlertUserIds: prevState.checkedAlertUserIds.slice(0, -1),
    }));
  } else if (getIdPos > 0) {
    // Remove an ID in the middle of the array
    setState((prevState) => ({
      checkedAlertUserIds: [
        ...prevState.checkedAlertUserIds.slice(0, getIdPos),
        ...prevState.checkedAlertUserIds.slice(getIdPos + 1),
      ],
    }));
  }
};

const handleDeleteNotification = ({
  selectedNotification,
  notificationHistory,
  setState,
  t,
  firebase,
  parentGroupId,
}) => {
  const notifyRef = firebase.database().ref(`/notifications/${parentGroupId}`);
  
  setState({ loadingDelete: true });

  // Remove the notification from Firebase
  notifyRef.child(selectedNotification.fb_key).remove();

  // Remove the notification from local state
  const notificationIndex = notificationHistory.findIndex(
    (notification) => notification.fb_key === selectedNotification.fb_key
  );

  setState({
    notificationHistory: [
      ...notificationHistory.slice(0, notificationIndex),
      ...notificationHistory.slice(notificationIndex + 1),
    ],
    hasError: true,
    errorMessage: `「${selectedNotification.msg_title}」${t("has been removed")}`,
    errorType: "success",
    loadingDelete: false,
    deleteNotificationOpen: false,
  });
};

const checkNotificationHistory = ({ parentGroupId, setState, firebase }) => {
  const notifyRef = firebase.database().ref(`/notifications/${parentGroupId}`);
  
  notifyRef.on(
    "value",
    (snapshot) => {
      if (snapshot.val()) {
        const notificationKeys = Object.keys(snapshot.val());
        const notificationValues = Object.values(snapshot.val());

        notificationValues.forEach((notification, index) => {
          notification.fb_key = notificationKeys[index];
        });

        setState({
          notificationHistory: notificationValues.reverse(),
        });
      }
    },
    (errorObject) => {
      console.error("The read failed: " + errorObject.code);
    }
  );

  // Automatically return the ref for component cleanup
  return notifyRef;
};


export {
  sendNotifications,
  sendNotificationsUser,
  handleCheckAllUserNotify,
  handleCheckGroupNotify,
  handleCheckUserNotify,
  handleDeleteNotification,
  checkNotificationHistory
};
