import moment from "moment";
import "moment/locale/ja";
const geocore = require("@mekasmith/geocore");
var _ = require("lodash");

const formatListings = async ({
  places,
  nextPage,
  newPlacesList,
  parentGroup,
  isLiveApp,
  allocatedPlaceAmount,
  currentPlaces,
  setState,
  getLiveStreamImagesAndVideo,
  getImages,
  sortByCreateTime,
  viewablePlacesAmount,
  setCheckboxData,
  createMarkers,
  filterItems,
  runHeartbeat,
}) => {
  const { customData } = parentGroup;

  //before this we need to to remove either live or not live places
  //isLiveApp
  if(isLiveApp) {
    places = places.filter((place) => place.id.includes("-LIVE-"));
  } else {
    places = places.filter((place) => !place.id.includes("-LIVE-"));
  }
  
  const quickFilteredPlaces = places.slice(0, 200);
  // Create a map for quicker lookups of existing places.
  const prevPlacesMap = new Map();
  if (currentPlaces && currentPlaces.length > 0) {
    currentPlaces.forEach((p) => prevPlacesMap.set(p.placeId, p));
  }

  const fetchPlaceData = async (place, index) => {
    const tagLevelName = checkTagLevelName(place.tags);

    const placeBase = {
      index: index,
      comment: place.customData ? place.customData["comment.1"] : "",
      updatedBy: place.customData ? place.customData["updatedBy"] : "",
      createTime: moment.utc(place.createTime).local().format("YYYY/MM/DD (ddd) HH:mm"),
      updateTime: moment.utc(place.updateTime).local().format("YYYY/MM/DD (ddd) HH:mm"),
      geo: place.point,
      title: place.shortDescription,
      groupName: place.customData ? place.customData["group.name"] : "",
      groupId: checkGroupId(place.tags),
      placeId: place.id,
      incidentTags: checkIncidentTags(place.tags),
      hazardLevel: {
        id: checkTagLevelId(place.tags),
        name: tagLevelName,
        customName: customData ? customData[tagLevelName] : "",
      },
      user: place.customData ? place.customData["user.name"] : "",
      taisaku: place.customData ? place.customData["taisaku"] : "",
      duration: place.customData ? place.customData["video_duration"] : "",
      isLiveStream: place.id.includes("-LIVE-"),
      is_webrtc: place.customData ? place.customData["is_webrtc"] === "true" : false,
      isStreaming: place.customData ? place.customData["is_live"] === "true" : false,
      streamHasStorage: place.customData ? place.customData["has_storage"] === "true" : false,
      isPublic: place.customData ? place.customData["isPublic"] === "true" : false,
      hasRead: place.customData ? place.customData["hasRead"] === "true" : false,
      isDeleted: place.customData ? place.customData["deleted"] === "true" : false,
      isSos: place.customData ? place.customData["is_sos"] === "true" : false,
      video_duration: place.customData && place.customData["video_duration"] ? place.customData["video_duration"] : 0,
      realtimeData: place.customData && place.customData["is_webrtc"] && place.customData["realtimeData"] ? place.customData["realtimeData"] : null,
      isViewable: true,
    };

    if (placeBase.isLiveStream && isLiveApp && (placeBase.isStreaming || placeBase.streamHasStorage)) {
      const urlObj = await getLiveStreamImagesAndVideo(place, placeBase.streamHasStorage);
      return {
        ...placeBase,
        images: urlObj && urlObj.video_screen_url ? [urlObj.video_screen_url] : null,
        video: urlObj && urlObj.video_storage_url ? [urlObj.video_storage_url] : null,
      };
    } else {
      const urlObj = await getImages(place);
      return {
        ...placeBase,
        images: urlObj && urlObj.photoURLs ? urlObj.photoURLs : null,
        video: urlObj && urlObj.videoURL ? urlObj.videoURL : null,
      };
    }
  };

  // Parallelize fetching data.
  const promises = quickFilteredPlaces.map((place, index) => {
    const prevPlace = prevPlacesMap.get(place.id);
    return prevPlace ? Promise.resolve(prevPlace) : fetchPlaceData(place, index);
  });

  const placesList = await Promise.all(promises);
  const sortedPlaces = sortByCreateTime(placesList);
  // still has the full amount here...
  if (nextPage) {
    setState((prevState) => ({
      places: [...prevState.places, ...sortedPlaces],
      loadingPlacesByArea: false,
      loadingTabSwitch: false,
      publicListEmptyAlert: false,
      amountSelected: 0,
    }), () => {
      viewablePlacesAmount();
      setCheckboxData();
      createMarkers(nextPage);
      filterItems();
      runHeartbeat();
    });
  } else {
    setState({
      places: sortedPlaces,
      loadingPlacesByArea: false,
      loadingTabSwitch: false,
      publicListEmptyAlert: false,
      amountSelected: 0,
    }, () => {
      viewablePlacesAmount();
      setCheckboxData();
      createMarkers(nextPage, newPlacesList);
      filterItems();
      runHeartbeat();
    });
  }
};


const filterDeleted = places => {
  // Filter out all of the deleted places to be removed at a later date
  const filteredPlaces = places.filter(place => {
    return place.customData && place.customData.deleted !== "true"
  });

  return filteredPlaces;
};

const checkGroupId = tags => {
  let groupId = "";
  if (tags) {
    tags.forEach(tag => {
      if (tag.id.includes("TAG-TNHP-1-PT-")) {
        return (groupId = tag.id.replace("TAG-TNHP-1-PT-", ""));
      }
    });
    return groupId;
  } else {
    return "";
  }
};

const checkIncidentTags = tags => {
  let incidentTags = [];
  if (tags && tags.length > 0) {
    tags.forEach(tag => {
      if (tag.id.includes("TAG-TNHP-1-INC-")) {
        incidentTags.push(tag);
      }
    });
  } 
  return incidentTags;
};

const checkTagLevelId = tags => {
  let tagId = "";
  if (tags) {
    tags.forEach(tag => {
      if (tag.id.includes("TAG-TNHP-1-SEV-")) {
        return (tagId = tag.id);
      }
    });
    return tagId;
  } else {
    return "";
  }
};

const checkTagLevelName = tags => {
  let tagName = "";
  if (tags) {
    tags.forEach(tag => {
      if (tag.id.includes("TAG-TNHP-1-SEV-")) {
        return (tagName = tag.name);
      }
    });
    return tagName;
  } else {
    return "";
  }
};

const hasLevelNumber = (levelName) => {
  switch (levelName) {
    case "大至急！":
      return "1";
    case "至急":
      return "2";
    case "通常":
      return 3;
    case "参考":
      return 4;
    default:
      break;
  }
};

const sortByCreateTime = placesList => {
  // Descending
  placesList.sort((a, b) => {
    let aDate = moment(a.createTime, "YYYY/MM/DD HH:mm:ss").unix();
    let bDate = moment(b.createTime, "YYYY/MM/DD HH:mm:ss").unix();
    return aDate < bDate ? 1 : (aDate > bDate ? -1 : 0);
  });
  return placesList;
};

const getPlaces = async ({
  nextPage,
  t,
  groups,
  queryPlaces,
  formatListings,
  filterItems,
  setState,
}) => {
  // Start loading places
  setState({ loadingPlaces: true });

  // Build group IDs
  const groupIds = groups.map(group => "TAG-TNHP-1-PT-" + group.id);

  // Query places
  const places = await queryPlaces(groupIds, nextPage);

  if (places && places.length > 0) {
    setState({
      mostRecentPlaceTimestamp: new Date(places[0].createTime).getTime(),
    });
    formatListings(places, nextPage);
  } else {
    filterItems();

    setState({ 
      hasError: true,
      errorType: "warning",
      errorMessage: t("No information found for the specified search area and filters"),
      canQueryMore: false,
      heartbeatLastCheck: Date.now() / 1000,
    });
  }
};

const queryPlaces = async ({
  groupIds,
  nextPage,
  map,
  filterRange,
  pageNumber,
  customData,
}) => {
  const query = new geocore.places.query();

  // Handle initial map settings based on customData
  if (customData && customData.initialLat && customData.initialLng && !nextPage) {
    const zoomLevel = customData.initialZoom ? parseInt(customData.initialZoom) : 8;
    const lat = parseFloat(customData.initialLat);
    const lng = parseFloat(customData.initialLng);

    map.setZoom(zoomLevel);
    map.setCenter({ lat, lng });
  }

  // Get map bounds
  const bounds = map.getBounds();
  const north = bounds.getNorthEast().lat();
  const south = bounds.getSouthWest().lat();
  const east = bounds.getNorthEast().lng();
  const west = bounds.getSouthWest().lng();

  // Query places
  const places = await query
    .withTagIds(groupIds)
    .withTagDetails()
    .createdAfter(filterRange.startDate)
    .createdBefore(filterRange.endDate)
    .orderByRecentlyCreated()
    .setRectangle(south, west, north, east)
    .page(pageNumber)
    .numberPerPage(200)
    .withinRectangle()
    .all();
  // Filter out deleted places
  const filteredPlaces = filterDeleted(places);
  return filteredPlaces;
};

const queryPlacesDateRange = async ({
  startDate,
  endDate,
  groupIds,
  range,
  map,
  setState,
}) => {
  const bounds = map.getBounds();
  const north = bounds.getNorthEast().lat();
  const south = bounds.getSouthWest().lat();
  const east = bounds.getNorthEast().lng();
  const west = bounds.getSouthWest().lng();

  // Convert startDate and endDate to GMT
  const localStartOfDay = moment(startDate * 1000).startOf("day");
  const localEndOfDay = moment(endDate * 1000).endOf("day");
  const formattedStartDate = localStartOfDay.utc().startOf("day").local().format("YYYY/MM/DD HH:mm:ss");
  const formattedEndDate = localEndOfDay.utc().endOf("day").local().format("YYYY/MM/DD HH:mm:ss");

  // Update the state for filterRange
  setState({
    filterRange: {
      range: range,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    },
  });

  // Execute the query
  const query = new geocore.places.query();
  const places = await query
    .withTagIds(groupIds)
    .withTagDetails()
    .createdAfter(formattedStartDate)
    .createdBefore(formattedEndDate)
    .orderByRecentlyCreated()
    .setRectangle(south, west, north, east)
    .numberPerPage(200)
    .withinRectangle()
    .all();//if you use .page(1) it will only return max 500 places

  // Filter out deleted places
  const filteredPlaces = filterDeleted(places);
  return filteredPlaces;
};

const updatePlaceListIncidentTags = ({ places, selectedPlaceIds, selectedIncidentIds, selectedIncidents }) => {
  const updatedPlaces = _.cloneDeep(places);

  updatedPlaces.forEach((place) => {
    if (selectedPlaceIds.includes(place.placeId)) {
      const incidentTags = place.incidentTags;

      // Filter incidents that are not already tagged
      const newSelectedIncidents = selectedIncidents.filter(
        (selectedIncident) => !incidentTags.some((incidentTag) => incidentTag.id === selectedIncident.id)
      );

      // Add the new incidents
      place.incidentTags.push(...newSelectedIncidents);
    }
  });

  return updatedPlaces;
};

const handleCreateIncidentTag = async ({
  checkboxData,
  selectedIncidents,
  getIncidentTags,
  handleSelectedClose,
  setState,
  t,
  places,
}) => {
  setState({ loadingAction: true });

  const selectedIncidentIds = [];
  const selectedPlaceIds = [];
  const tagPromises = [];

  selectedIncidents.forEach((incident) => {
    selectedIncidentIds.push(incident.id);
  });

  Object.entries(checkboxData).forEach(([placeId, isSelected]) => {
    if (isSelected) selectedPlaceIds.push(placeId);
  });

  geocore.places.tags.addById = function (id, tagIds) {
    return geocore.post(
      `/places/${id}/tags?tag_ids=${encodeURIComponent(tagIds.join(","))}`,
      null
    );
  };

  selectedPlaceIds.forEach((placeId) => {
    const tagPromise = geocore.places.tags.addById(placeId, selectedIncidentIds);
    tagPromises.push(tagPromise);
  });

  try {
    await Promise.all(tagPromises);
  } catch (e) {
    console.error(e);
    setState({
      loadingAction: false,
      hasError: true,
      errorType: "error",
      errorMessage: t("An error has occurred. Please try again."),
    });
    return;
  }

  // Update place list incident tags internally
  const updatedPlaces = updatePlaceListIncidentTags({
    places,
    selectedPlaceIds,
    selectedIncidentIds,
    selectedIncidents,
  });
  setState({ places: updatedPlaces });

  getIncidentTags(true);
  handleSelectedClose();

  setState({
    loadingAction: false,
    hasError: true,
    errorType: "success",
    errorMessage: t("Incident information has been updated."),
    checkboxData: {},
    allChecked: false,
    amountSelected: 0,
  });
};

const getPlacesByArea = async ({
  resetFilters,
  newPlacesList,
  map,
  filterRange,
  groups,
  t,
  clearPlaceListMarkers,
  formatListings,
  setState,
  filterDeleted,
  sortByCreateTime,
  ignoreViewMore
}) => {
  setState({
    loadingPlacesByArea: true,
    alertCounter: 0,
    alertNewPlace: false,
  });
  if (resetFilters) {
    setState((prevState) => ({
      filterByLists: {
        緊急度: [],
        端末表示: [],
        表示期間: [],
        端末最終地点: [],
        施設情報: [],
        管理者コメント: [],
        ユーザ: [],
        インシデント: [],
        "Urgency Level": [],
        "Groups": [],
        "Date Range": [],
        "Device Last Location": [],
        "Facility Information": [],
        "Admin Comment": [],
        "User": [],
        "Incident": []
      },
      filterByTaisaku: false,
    }));
    document.getElementById("btn-week").click();
    return;
  }
  clearPlaceListMarkers(resetFilters);
  const groupIds = groups.map((group) => "TAG-TNHP-1-PT-" + group.id);
  const bounds = map.getBounds();
  const north = bounds.getNorthEast().lat();
  const south = bounds.getSouthWest().lat();
  const east = bounds.getNorthEast().lng();
  const west = bounds.getSouthWest().lng();

  const query = new geocore.places.query();
  const places = await query
    .withTagIds(groupIds)
    .withTagDetails()
    .createdAfter(filterRange.startDate)
    .createdBefore(filterRange.endDate)
    .orderByRecentlyCreated()
    .setRectangle(south, west, north, east)
    .page(1)
    .numberPerPage(200)
    .withinRectangle()
    .all();
  const filteredPlaces = filterDeleted(places);
  const areaPlaces = sortByCreateTime(filteredPlaces);
  if (areaPlaces && areaPlaces.length > 0) {
    const canQueryMore = areaPlaces.length > 25;
    setState({
      canQueryMore,
      pageNumber: 1,
      pagePlaceAmount: 200,
      allocatedPlaceAmount: 50,
    });
    formatListings(areaPlaces, false, newPlacesList);
  } else {
    setState({
      hasError: true,
      errorType: "warning",
      errorMessage: t("No information found for the specified search area and filters"),
      loadingPlacesByArea: false,
      loadingPlaces: false,
      loadingMoreList: false,
      loadingTabSwitch: false,
      placeAmount: 0,
      taisakuAmount: 0,
      checkboxData: {},
      amountSelected: 0,
      places: null,
      canQueryMore: false,
      pageNumber: 1,
      allocatedPlaceAmount: 50,
      pagePlaceAmount: 200,
    });
  }
};


const updatePlace = async ({
  updatedPlace,
  incidentTagsList,
  updatedHazardLevel,
  parentGroup,
  currentPlaces,
  getLiveStreamImagesAndVideo,
  getImages,
  viewableIncidentTags,
  viewablePlacesAmount,
  createMarkerEditPlace,
  map,
  setState,
}) => {
  const placeIndex = currentPlaces.findIndex((place) => place.placeId === updatedPlace.id);

  const comment = updatedPlace.customData?.["comment.1"] || "";
  const updatedBy = updatedPlace.customData?.updatedBy || "";
  const createTime = moment.utc(updatedPlace.createTime).local().format("YYYY/MM/DD (ddd) HH:mm");
  const updateTime = moment().format("YYYY/MM/DD (ddd) HH:mm");
  const geo = updatedPlace.point;
  const title = updatedPlace.shortDescription;
  const groupName = updatedPlace.customData?.["group.name"] || "";
  const groupId = checkGroupId(updatedPlace.tags);
  const placeId = updatedPlace.id;
  const tagId = updatedHazardLevel?.id || checkTagLevelId(updatedPlace.tags);
  const tagLevelName = updatedHazardLevel?.name || checkTagLevelName(updatedPlace.tags);
  const customName = parentGroup.customData?.[tagLevelName] || "";
  const incidentTags = checkIncidentTags(incidentTagsList);
  const user = updatedPlace.customData?.["user.name"] || "";
  const taisaku = updatedPlace.customData?.taisaku || "";
  const duration = updatedPlace.customData?.stream_duration_in_sec || "";
  const isLiveStream = updatedPlace.id.includes("-LIVE-");
  const isViewable = viewableIncidentTags(incidentTags);

  const flags = {
    is_webrtc: updatedPlace.customData?.is_webrtc === "true",
    isStreaming: updatedPlace.customData?.is_live === "true",
    streamHasStorage: updatedPlace.customData?.has_storage === "true",
    isPublic: updatedPlace.customData?.isPublic === "true",
    hasRead: updatedPlace.customData?.hasRead === "true",
    isDeleted: updatedPlace.customData?.deleted === "true",
    isSos: updatedPlace.customData?.is_sos === "true",
  };

  const formatPlace = async (urlObj) => ({
    index: placeIndex,
    placeId,
    hazardLevel: { id: tagId, name: tagLevelName, customName },
    incidentTags,
    createTime,
    updateTime,
    title,
    groupName,
    groupId,
    user,
    comment,
    updatedBy,
    images: urlObj?.video_screen_url ? [urlObj.video_screen_url] : null,
    video: urlObj?.video_storage_url ? [urlObj.video_storage_url] : null,
    geo,
    video_duration: updatedPlace.customData?.video_duration || 0,
    isViewable,
    taisaku,
    ...flags,
    duration,
  });

  if (isLiveStream && (flags.isStreaming || flags.streamHasStorage)) {
    const urlObj = await getLiveStreamImagesAndVideo(updatedPlace, flags.streamHasStorage);
    const formattedPlace = await formatPlace(urlObj);

    setState((prevState) => ({
      places: [
        ...prevState.places.slice(0, placeIndex),
        formattedPlace,
        ...prevState.places.slice(placeIndex + 1),
      ],
      amountSelected: 0,
      checkboxData: {},
      allChecked: false,
    }), () => {
      viewablePlacesAmount();
      createMarkerEditPlace(formattedPlace);
      setState({ open: false, selectedPlace: formattedPlace });
      map.setZoom(15);
      map.setCenter({ lat: formattedPlace.geo.latitude, lng: formattedPlace.geo.longitude });
    });
  } else {
    const urlObj = await getImages(updatedPlace);
    const formattedPlace = await formatPlace(urlObj);

    setState((prevState) => ({
      places: [
        ...prevState.places.slice(0, placeIndex),
        formattedPlace,
        ...prevState.places.slice(placeIndex + 1),
      ],
      amountSelected: 0,
      checkboxData: {},
      allChecked: false,
    }), () => {
      viewablePlacesAmount();
      createMarkerEditPlace(formattedPlace);
      setState({ open: false });
      map.setZoom(15);
      map.setCenter({ lat: formattedPlace.geo.latitude, lng: formattedPlace.geo.longitude });
    });
  }
};


export {
  filterDeleted,
  checkGroupId,
  checkIncidentTags,
  checkTagLevelId,
  checkTagLevelName,
  formatListings,
  hasLevelNumber,
  sortByCreateTime,
  getPlaces,
  handleCreateIncidentTag,
  queryPlaces,
  queryPlacesDateRange,
  getPlacesByArea,
  updatePlace
};
